import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Investidores from "./Investidores";
import { Box, Button, Container, MenuItem, Select, TextField, Typography } from "@mui/material";


export const formatBankAccount = (text) => {
    if (text) {
        const accountNumber = text.replace(/[^0-9a-zA-Z]/g, '').toUpperCase();

        if (accountNumber.length <= 1) {
            return accountNumber;
        }

        const lastCharacter = accountNumber.slice(-1);
        const otherCharacters = accountNumber.slice(0, -1);

        const formattedAccount = otherCharacters.replace(/(.{1,4})(?=(.{3})+(?!.))/g, '$1 ') + '-' + lastCharacter;

        return formattedAccount;
    }
};
export const formatarTelefone = (input) => {
    // Remove todos os caracteres não numéricos do input
    if (input) {
        const numeros = input.replace(/\D/g, '');

        // Aplica a máscara (xx) xxxxx-xxxx enquanto o usuário digita
        let telefoneFormatado = '';
        for (let i = 0; i < numeros.length; i++) {
            if (i === 0) {
                telefoneFormatado = '(';
            } else if (i === 2) {
                telefoneFormatado += ') ';
            } else if (i === 7) {
                telefoneFormatado += '-';
            }
            telefoneFormatado += numeros[i];
        }

        return telefoneFormatado;
    }
};
const Investidor = () => {
    const navigate = useNavigate();
    const user = {
        data: {
            razaoSocial: "Jozinho"
        }
    }
    const [available, setAvailable] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [razaoSocial, setRazaoSocial] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [credorNumero, setCredorNumero] = useState('');
    const [credorRua, setCredorRua] = useState('');
    const [credorCidade, setCredorCidade] = useState('');
    const [credorBairro, setCredorBairro] = useState('');
    const [credorEstado, setCredorEstado] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [cep, setCep] = useState('');
    const [dadosBancarios, setDadosBancarios] = useState('');
    const [conta, setConta] = useState('');
    const [agency, setAgency] = useState('');
    const [banks, setBanks] = useState([]);
    const [selectedBank, setSelectedBank] = useState('');
    const [bankNumber, setBankNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [credorComplemento, setCredorComplemento] = useState('');
    const [tipoDeInstituicao, setTipoDeInstituicao] = useState('');
    const [investidores, setInvestidores] = useState(false);
    const [representantes, setRepresentantes] = useState([{ nome: '', email: '' }]);

    const toggleInvestidores = () => {
        setInvestidores(!investidores);
    }

    const validateInputs = () => {
        if (!razaoSocial || razaoSocial === "") {
            alert('Por favor, insira uma Razão Social válida.');
            return false;
        }

        if (!cnpj || cnpj.replace(/\D/g, '').length !== 14) {
            alert('Por favor, insira um CNPJ válido.');
            return false;
        }

        if (!cep || cep.replace(/\D/g, '').length !== 8) {
            alert('Por favor, insira um CEP válido.');
            return false;
        }

        if (!credorRua || credorRua.trim() === "") {
            alert('Por favor, insira uma Rua válida.');
            return false;
        }

        if (!credorNumero) {
            alert('Por favor, insira um Número válido.');
            return false;
        }

        if (!credorBairro) {
            alert('Por favor, insira um Bairro válido.');
            return false;
        }

        if (!credorCidade) {
            alert('Por favor, insira uma Cidade válida.');
            return false;
        }

        if (!credorEstado) {
            alert('Por favor, insira um Estado válido.');
            return false;
        }

        if (!representantes || representantes.length === 0) {
            alert('Por favor, insira um Nome de Usuário válido.');
            return false;
        }

        if (!telefone) {
            alert('Por favor, insira um Número de Telefone válido.');
            return false;
        }

        if (!selectedBank) {
            alert('Por favor, selecione um Banco.');
            return false;
        }

        if (!bankNumber) {
            alert('Por favor, insira um Número de Banco válido.');
            return false;
        }

        if (!agency) {
            alert('Por favor, insira uma Agência válida.');
            return false;
        }

        if (!conta) {
            alert('Por favor, insira um Número de Conta válido.');
            return false;
        }

        return true; // Todos os campos estão válidos
    };

    const handleSearch = async () => {

        try {
            setLoading(true);
            const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
            console.log('====================================');
            console.log(response.data, 'response.data');
            console.log('====================================');
            setCredorComplemento(response.data.complemento);
            setCredorCidade(response.data.localidade);
            setCredorBairro(response.data.bairro);
            setCredorEstado(response.data.uf);
            setCredorRua(response.data.logradouro);
        } catch (error) {
            console.error('Error fetching address:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        // Esta função será executada após cada renderização do componente
        // Role a página para o topo
        window.scrollTo(0, 0);
    }, []);

    const keywordsToPrioritize = ['caixa eco', 'itaú uni', 'bco do brasil', 'bradesco s', 'santander', 'original', 'banco inter', 'bdigital'];

    const sortedBanks = [...banks].sort((a, b) => {
        const bankNameA = a.name ? a.name.toLowerCase() : '';
        const bankNameB = b.name ? b.name.toLowerCase() : '';

        const priorityA = getPriority(bankNameA);
        const priorityB = getPriority(bankNameB);

        if (priorityA !== priorityB) {
            return priorityA - priorityB; // Coloque o banco com prioridade maior primeiro
        } else {
            return bankNameA.localeCompare(bankNameB); // Ordem alfabética padrão
        }
    });

    function getPriority(name) {
        for (let i = 0; i < keywordsToPrioritize.length; i++) {
            if (name.includes(keywordsToPrioritize[i])) {
                return i; // Retorna a posição da primeira correspondência encontrada
            }
        }
        return keywordsToPrioritize.length; // Se não houver correspondências, use a prioridade máxima
    }

    useEffect(() => {
        fetch('https://brasilapi.com.br/api/banks/v1')
            .then(response => response.json())
            .then(data => setBanks(data))
            .catch(error => console.error('Ocorreu um erro ao buscar os bancos:', error));
        setDadosBancarios(`Banco: ${selectedBank}-${bankNumber}/Agencia: ${agency}/Conta: ${conta}`);
    }, [available]);

    const handleBankChange = (event) => {
        const selectedBankName = event.target.value;
        setSelectedBank(selectedBankName);
        const selectedBankData = banks.find(bank => bank.name === selectedBankName);

        if (selectedBankData) {
            setBankNumber(selectedBankData.code);
        }
        else if (selectedBankName === 'BNK Digital') {
            setBankNumber(547);
        } else {
            setBankNumber('');
        }
    };

    const handleBankNumberChange = (event) => {
        const bankNumber = event.target.value;
        setBankNumber(bankNumber);

        const selectedBankData = banks.find(bank => bank.code == bankNumber);

        console.log("bankNumber:", bankNumber);
        console.log("selectedBankData:", selectedBankData);
        console.log("bankNumber:", bankNumber);
        console.log("Banks:", banks);


        if (selectedBankData) {
            setSelectedBank(selectedBankData.name);
        } else {
            setSelectedBank(''); // Limpar o campo "Dados Bancários" se o número do banco não corresponder a nenhum banco na lista.
        }
    };


    const handlePdf = async (event) => {
        event.preventDefault();
        validateInputs();
        if (!validateInputs()) {
            return; // Evitar a navegação se as validações não passarem
        }

        let dados = {
            userRef: 'c2quick',
            razaoSocial: razaoSocial,
            cnpj: cnpj,
            cep: cep,
            rua: credorRua,
            bairro: credorBairro,
            numero: credorNumero,
            complemento: credorComplemento,
            cidade: credorCidade,
            estado: credorEstado,
            representantes: representantes,
            telefone: telefone,
            banco: selectedBank,
            numeroDoBanco: bankNumber,
            conta: conta,
            agencia: agency,
        };

        try {
            // Fazer uma chamada Axios para a rota de criação do investidor
            console.log(dados);
            console.log(dados.representantes)
            const headers = {
            }
            const response = await axios.post('https://c2easybe-production.up.railway.app/investidor', dados, headers);

            if (response.status === 201) {
                localStorage.setItem('investidorPreenchido', true);
                localStorage.setItem('investidor', JSON.stringify(dados));
                window.alert('Investidor Cadastrado!');
                navigate('/Emissor');
            } else {
                window.alert('Erro ao cadastrar investidor!');
            }
        } catch (error) {
            console.error('Erro ao criar investidor:', error);
            // Lida com erros de requisição, se necessário
        }
    };

    const handleExpand = () => {
        setIsExpanded(!isExpanded);
    };
    useEffect(() => {
        handleExpand()

    }, [])

    const addRepresentante = () => {
        setRepresentantes([...representantes, { nome: '', email: '' }]); // Adiciona um novo representante vazio
    };

    const removeRepresentante = (index) => {
        const updatedRepresentantes = [...representantes];
        updatedRepresentantes.splice(index, 1);
        setRepresentantes(updatedRepresentantes);
    };

    const handleRepresentanteChange = (index, field, value) => {
        const updatedRepresentantes = [...representantes];
        updatedRepresentantes[index][field] = value;
        setRepresentantes(updatedRepresentantes);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Header selected={'investidor'} />

            <Container sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingY: '20px',
                marginY: '20px',
                borderRadius: 2,
            }}>
                {!investidores &&
                    <Button onClick={toggleInvestidores}>
                        {"Cadastrar novo investidor"}
                    </Button>
                }
                {investidores ?
                    <Container sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                    }}>
                        <Container>
                            <h1>Dados do investidor</h1>
                        </Container>
                        <Container sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                        }}>
                            <label htmlFor="" >
                                Razão Social:
                            </label>
                            <TextField
                                variant="outlined"
                                type="text"
                                placeholder="Insira a Razão Social"
                                value={razaoSocial}
                                onChange={(e) => { setRazaoSocial(e.target.value) }}
                                maxLength={120} />
                        </Container>
                        <Container sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                        }}>
                            <label htmlFor="" className="labelInputsDoc">
                                CNPJ:
                            </label>
                            <TextField
                                variant="outlined"
                                placeholder="Apenas Números"
                                value={cnpj}
                                onChange={(e) => { setCnpj(e.target.value) }}
                                maxLength={18}
                            />

                        </Container>
                        <Container sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                        }}>
                            <label htmlFor="" className="labelInputsDoc">
                                CEP:
                            </label>
                            <TextField
                                variant="outlined"
                                placeholder="Apenas Números"
                                value={cep}
                                onChange={(e) => { setCep(e.target.value) }}
                                onBlur={handleSearch} />{loading && <p width="10%">Buscando...</p>}

                        </Container>
                        <Container sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                        }}>
                            <label htmlFor="" className="labelInputsDoc">
                                Rua:
                            </label>
                            <TextField
                                variant="outlined"
                                placeholder="Rua Av."
                                value={credorRua}
                                onChange={(e) => { setCredorRua(e.target.value) }}
                            />

                        </Container>
                        <Container sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                        }}>
                            <label htmlFor="" className="labelInputsDoc">
                                Número:
                            </label>
                            <TextField
                                variant="outlined"
                                placeholder="Apenas Números"
                                value={credorNumero}
                                onChange={(e) => { setCredorNumero(e.target.value) }}
                            />

                        </Container>
                        <Container sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                        }}>
                            <label htmlFor="" className="labelInputsDoc">
                                Complemento:
                            </label>
                            <TextField
                                variant="outlined"
                                placeholder="Complemento"
                                value={credorComplemento}
                                onChange={(e) => { setCredorComplemento(e.target.value) }}
                            />

                        </Container>
                        <Container sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                        }}>
                            <label htmlFor="" className="labelInputsDoc">
                                Bairro:
                            </label>
                            <TextField
                                variant="outlined"
                                placeholder="Bairro"
                                value={credorBairro}
                                onChange={(e) => { setCredorBairro(e.target.value) }}
                            />

                        </Container>
                        <Container sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                        }}>
                            <label htmlFor="" className="labelInputsDoc">
                                Cidade:
                            </label>
                            <TextField
                                variant="outlined"
                                placeholder="Cidade"
                                value={credorCidade}
                                onChange={(e) => { setCredorCidade(e.target.value) }}
                            />
                        </Container>
                        <Container sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                        }}>
                            <label htmlFor="" className="labelInputsDoc">
                                Estado:
                            </label>
                            <TextField
                                variant="outlined"
                                placeholder="Estado"
                                value={credorEstado}
                                onChange={(e) => { setCredorEstado(e.target.value) }}
                            />
                        </Container>
                        <Container sx={{
                            width: '100%',
                        }}>
                            <Typography variant="h4">Representantes</Typography>
                        </Container>
                        {representantes.map((representante, index) => (
                            <Container key={index} sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                            }}>
                                <Container sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '50%',
                                }}>
                                    <label>
                                        Representante {index + 1}:
                                    </label>
                                    <TextField
                                        type="text"
                                        className={`inputsRep`}
                                        value={representante.nome}
                                        onChange={(e) => handleRepresentanteChange(index, 'nome', e.target.value)}
                                        maxLength={60}
                                    />
                                </Container>
                                <Container sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '50%',
                                }}>
                                    <label htmlFor="" className="labelInputsDoc">
                                        E-mail:
                                    </label>
                                    <TextField
                                        type="text"
                                        className={`inputsRep`}
                                        value={representante.email}
                                        onChange={(e) => handleRepresentanteChange(index, 'email', e.target.value)}
                                    />
                                </Container>
                                {index >= 0 && ( // Impede a remoção do primeiro campo
                                    <Button type="button" onClick={() => removeRepresentante(index)} className="buttonRep">-</Button>
                                )}
                            </Container>
                        ))}
                        <Button sx={{ width: '100%' }} onClick={addRepresentante}>+ Adicionar Representante</Button>

                        <Typography variant="h4" sx={{ width: '100%' }}>Informações complementares</Typography>
                        <Container sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                        }}>
                            <label htmlFor="" className="labelInputsDoc">
                                Telefone:
                            </label>
                            <TextField
                                variant="outlined"
                                value={formatarTelefone(telefone)}
                                onChange={(e) => { setTelefone(e.target.value) }}
                                maxLength={15} />
                        </Container>
                        <Container sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                        }}>
                            <label htmlFor="" className="labelInputsDoc">
                                Dados Bancários
                            </label>
                            <Select value={selectedBank} onChange={handleBankChange} className="selectDoc">
                                <MenuItem value="" className="optionInputsDoc">Selecione um banco</MenuItem>
                                <MenuItem value="BNK Digital" className="optionInputsDoc">BNK Digital</MenuItem>
                                {sortedBanks.map((bank, index) => (
                                    <MenuItem key={index} value={bank.name} className="optionInputsDoc">
                                        {bank.name}
                                    </MenuItem>
                                ))}
                            </Select>

                        </Container>
                        <Container sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                        }
                        }>
                            <label htmlFor="" className="labelInputsDoc">Numero do Banco</label>
                            <TextField
                                variant="outlined"
                                name="Número do banco"
                                placeholder="Selecione um banco"
                                value={bankNumber}
                                onChange={handleBankNumberChange}
                                maxLength={20}
                            />
                        </Container>
                        <Container sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                        }
                        }>
                            <label htmlFor="" className="labelInputsDoc">
                                Agência:
                            </label>
                            <TextField
                                variant="outlined"
                                placeholder="12345"
                                type="text"
                                value={agency}
                                onChange={(e) => { setAgency(e.target.value) }}
                                maxLength={8} />
                        </Container>
                        <Container sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                        }
                        }>
                            <label htmlFor="" className="labelInputsDoc">Conta</label>
                            <TextField
                                variant="outlined"
                                placeholder="123 456-X"
                                type="text"
                                value={formatBankAccount(conta)}
                                onChange={(e) => { setConta(e.target.value) }}
                                maxLength={13} />
                        </Container>
                        <Container sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                        }
                        }>
                            <label htmlFor="" className="labelInputsDoc">
                                Tipo de Instituição:
                            </label>
                            <Select
                                className="selectDoc"
                                value={tipoDeInstituicao}
                                onChange={(e) => setTipoDeInstituicao(e.target.value)}
                            >
                                <MenuItem value="FIDC">FIDC</MenuItem>
                                <MenuItem value="Factoring">Factoring</MenuItem>
                                <MenuItem value="Securitizadora">Securitizadora</MenuItem>
                            </Select>
                        </Container>
                        <Button className="buttonDoc" onClick={toggleInvestidores}>
                            Cancelar
                        </Button>
                        <Button className="buttonDoc" onClick={handlePdf} disabled={loading}>
                            {loading ? 'Salvando...' : 'Salvar Informações'}
                        </Button>
                    </Container>
                    :
                    <Investidores />
                }
            </Container>
        </Box>
    )
}

export default Investidor
import React, { useEffect, useState } from 'react';
import { Box, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, Typography } from "@mui/material";
import SideHeader from './Header';
import { theme } from './MainCalc';

const Resultado = ({ result }) => {

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        // Tentar obter calcData do localStorage
        const storedData = localStorage.getItem('calcData');
        if (storedData) {
            try {
                // Tentar converter os dados armazenados para um array
                const parsedData = JSON.parse(storedData);
                if (Array.isArray(parsedData)) {
                    setTableData(parsedData);
                } else {
                    // Se os dados não forem um array, usar um array vazio ou um valor padrão
                    setTableData([]);
                }
            } catch (error) {
                // Em caso de erro na conversão dos dados, usar um array vazio ou um valor padrão
                setTableData([]);
            }
        } else {
            // Se não houver dados no localStorage, usar um array vazio ou um valor padrão
            setTableData([]);
        }
    }, []);


    return (
        <Box sx={{
            display: 'flex',
        }}>
            <SideHeader selected="resultado" />
            <ThemeProvider theme={theme}>
            <Container sx={{
                width: '100%',
                backgroundColor: '#fff',
                borderRadius: '10px 10px 10px 10px',
                paddingY: '20px',
                marginY: '20px',
            }}>
                <Typography variant="h5">Resultado do Cálculo</Typography>
                <TableContainer >
                    <Table sx={{ minWidth: 650 }} aria-label="tabela de resultados">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Parcela</TableCell>
                                <TableCell align="center">Data de Vencimento</TableCell>
                                <TableCell align="right">Valor da Parcela</TableCell>
                                <TableCell align="right">Amortização</TableCell>
                                <TableCell align="right">Juros</TableCell>
                                <TableCell align="right">Saldo Devedor</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData.length > 0 && tableData.map((row, index) => (
                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row" align='center' >{row[0]}</TableCell>
                                    <TableCell align="center">{row[1]}/{row[2]}/{row[3]}</TableCell>
                                    <TableCell align="right">{new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    }).format(row[4])}</TableCell>
                                    <TableCell align="right">{new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    }).format(row[5])}</TableCell>
                                    <TableCell align="right">{new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    }).format(row[6])}</TableCell>
                                    <TableCell align="right">
                                        {new Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        }).format(row[7])}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
            </ThemeProvider>
        </Box>
    );
}

export default Resultado;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaPlus, FaTrashCan } from "react-icons/fa6";
import { GrDocumentUpdate } from "react-icons/gr";
import EmissorModal from "../Modals/UpdateEmissor";
import { IoIosCloudUpload } from "react-icons/io";
import { BsArrowLeft, BsEye } from "react-icons/bs";
import DetailsEmissor from "../Modals/EmissorModal";
import { FaEdit } from "react-icons/fa";
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
const Emissors = () => {
    const [emissores, setEmissores] = useState([]);
    const [selectedItemIndex, setSelectedItemIndex] = useState(null); // Estado para controlar o item selecionado
    const [selectedEmissor, setSelectedEmissor] = useState(null); // Estado para armazenar os detalhes do emissor selecionado
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [img, setImg] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deleteError, setDeleteError] = useState(false);
    const [documentFields, setDocumentFields] = useState([]);
    const [modalEmissor, setModalEmissor] = useState(false);
    const [modalEmissorIsOpen, setModalEmissorIsOpen] = useState(false);
    const [detailsEmissor, setDetailsEmissor] = useState(false);
    const url = 'https://c2easybe-production.up.railway.app';
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(3);
    const totalPages = Math.ceil(emissores.length / itemsPerPage);
    const goToNextPage = () => setCurrentPage((page) => Math.min(page + 1, totalPages));
    const goToPreviousPage = () => setCurrentPage((page) => Math.max(page - 1, 1));
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = emissores.slice(startIndex, endIndex);
    const toggleModal = () => {
        setModalEmissor(!modalEmissor);
    }
    const openEmissorModal = (emissor) => {
        console.log(emissor);
        setSelectedEmissor(emissor);
        setModalEmissorIsOpen(true);
    };

    const closeInvestorModal = () => {
        setModalEmissorIsOpen(false);
    };

    const user = JSON.parse(localStorage.getItem('user'));
    const userRef = `c2quick`;

    const headers = {
    }
    useEffect(() => {
        axios.get(`${url}/emissor?userRef=${userRef}`, headers)
            .then((response) => {
                setEmissores(response.data);
            })
            .catch((error) => {
                console.error('Erro ao fazer a solicitação:', error);
            });
    }, [userRef]);


    const removeLastDocumentField = () => {
        const updatedFields = [...documentFields];
        updatedFields.pop(); // Remove o último elemento do array
        setDocumentFields(updatedFields);
    };


    useEffect(() => {
        if (selectedItemIndex !== null) {
            const selectedEmissorData = emissores[selectedItemIndex];
            setSelectedEmissor(selectedEmissorData);
        } else {
            setSelectedEmissor(null);
        }
    }, [selectedItemIndex, emissores]);


    const handleDeleteInfos = (index) => {
        setSelectedItemIndex(index);
        setShowConfirmation(true);
    }

    const toggleDetails = (emissor) => {
        setSelectedEmissor(emissor)
        setDetailsEmissor(true);
    }

    const handleDelete = (index) => {
        if (index !== null) {
            const emissorId = emissores[index]._id;
            axios.delete(`${url}/emissor?id=${emissorId}`, headers)
                .then((response) => {
                    if (response.status === 204) {
                        setDeleteSuccess(true);
                        setTimeout(() => {
                            setDeleteSuccess(false);
                        }, 3000);
                        setSelectedItemIndex(null); // Limpa a seleção após a exclusão
                        setShowConfirmation(false);

                        // Após a exclusão bem-sucedida, obtenha a lista atualizada
                        axios.get(`${url}/emissor?userRef=${userRef}`, headers)
                            .then((response) => {
                                const updatedEmissores = response.data;
                                setEmissores(updatedEmissores.length > 0 ? updatedEmissores : []); // Verifica se a lista ficará vazia e ajusta emissores
                            })
                            .catch((error) => {
                                console.error('Erro ao fazer a solicitação:', error);
                            });
                    } else {
                        setDeleteError(true);
                        setTimeout(() => {
                            setDeleteError(false);
                        }, 3000);
                    }
                })
                .catch((error) => {
                    console.error('Erro ao fazer a solicitação:', error);
                });
        }
    };
    const handleDeleteConfirmation = (index) => {
        setSelectedItemIndex(index);
        setShowConfirmation(true);
    };

    const navigate = useNavigate();
    const handleUseEmissor = () => {
        console.log(selectedEmissor);
        localStorage.setItem('emissorPreenchido', true);
        localStorage.setItem('emissor', JSON.stringify(selectedEmissor));
        window.alert('Emissor Selecionado!');
        navigate('/minuta')
    }

    const getCurrentEmissores = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return emissores.slice(startIndex, endIndex);
    };

    // Função para lidar com a mudança de página
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };
    return (
        <Container>
            <Container>
                {emissores.length > 0 ? (
                    <Container>
                        <h3 className="confirmationMessage1">Selecione o emissor para emitir uma Nota comercial</h3>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Razão Social</TableCell>
                                        <TableCell align="right">CNPJ</TableCell>
                                        <TableCell align="right">Representante</TableCell>
                                        <TableCell align="right">Email</TableCell>
                                        <TableCell align="right">Cargo</TableCell>
                                        <TableCell align="right">Ações</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {currentItems.map((emissor, index) => (
                                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell>
                                                {emissor.razaoSocial.length > 20
                                                    ? `${emissor.razaoSocial.substring(0, 20)}...`
                                                    : emissor.razaoSocial}
                                            </TableCell>
                                            <TableCell align="right">{emissor.cnpj}</TableCell>
                                            <TableCell align="right">
                                                {emissor.representante.length > 20
                                                    ? `${emissor.representante.substring(0, 20)}...`
                                                    : emissor.representante}
                                            </TableCell>
                                            <TableCell align="right">{emissor.email}</TableCell>
                                            <TableCell align="right">{emissor.cargo}</TableCell>
                                            <TableCell align="center">
                                                    <Tooltip title="Visualizar">
                                                        <IconButton onClick={() => toggleDetails(emissor)}>
                                                            <BsEye color="#1a2f67" size={20} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Excluir">
                                                        <IconButton onClick={() => handleDeleteConfirmation(index)}>
                                                            <FaTrashCan size={20} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Editar">
                                                        <IconButton onClick={() => openEmissorModal(emissor)}>
                                                            <FaEdit size={20} />
                                                        </IconButton>
                                                    </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Container>
                ) : (
                    <div className="investidorDiv1">Nenhum emissor encontrado</div>
                    )}
                    <div className="pagination">
                <button onClick={goToPreviousPage} disabled={currentPage === 1}>Anterior</button>
                <span>Página {currentPage} de {totalPages}</span>
                <button onClick={goToNextPage} disabled={currentPage === totalPages}>Próxima</button>
            </div>
                {deleteSuccess && <p className="successMessage">Emissor Excluído com sucesso!</p>}
                {deleteError && <p className="errorMessage">Falha ao Excluir emissor.</p>}
            </Container>
            {detailsEmissor && (
                <DetailsEmissor
                    selectedEmissor={selectedEmissor}
                    handleSave={handleUseEmissor}
                    onClose={() => setDetailsEmissor(false)}
                />
            )}
            {modalEmissorIsOpen && (
                <EmissorModal
                    isOpen={modalEmissorIsOpen}
                    onClose={toggleModal}
                    emissorData={selectedEmissor}
                />
            )}
        </Container>
    )
}

export default Emissors;

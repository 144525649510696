import { Box, Container, TextField, Button, Typography, Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import SideHeader from "./Header";
import { NumericFormat } from 'react-number-format';
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';

export const theme = createTheme({
    typography: {
        fontSize: 16,
        fontFamily: [
            'Poppins',
            'sans-serif',

        ].join(','),
    }

});
const MainCalc = () => {
    const [formData, setFormData] = useState({
        total: '',
        taxa: '',
        parcelas: '',
        carencia: '',
        vencimentoDia: '',
        vencimentoMes: '',
        vencimentoAno: '',
        taxaEmissao: '750',
        custoCapital: ''
    });

    const navigation = useNavigate();

    useEffect(() => {
        // Carregar os dados salvos no localStorage
        const savedData = localStorage.getItem('formData');
        if (savedData) {
            const parsedData = JSON.parse(savedData);
            // Verifique se todos os campos esperados estão presentes e se são do tipo correto
            const loadedData = {
                total: parsedData.total || '',
                taxa: parsedData.taxa || '',
                parcelas: parsedData.parcelas || '',
                carencia: parsedData.carencia || '',
                vencimentoDia: parsedData.vencimentoDia || '',
                vencimentoMes: parsedData.vencimentoMes || '',
                vencimentoAno: parsedData.vencimentoAno || '',
                taxaEmissao: '750' || '750',
                custoCapital: parsedData.custoCapital || ''
            };
            setFormData(loadedData);
        }
    }, []);

    const handleChange = (e) => {
        const fieldName = e.target.name;
        let updatedValue = e.target.value;

        // Se o campo for "parcelas", limita o valor a 6
        if (fieldName === 'parcelas') {
            updatedValue = Math.min(updatedValue, 6);
        }
        if (fieldName === 'carencia') {
            updatedValue = Math.min(updatedValue, 5);
        }

        let updatedFormData = { ...formData, [fieldName]: updatedValue };
        setFormData(updatedFormData);
        localStorage.setItem('formData', JSON.stringify(updatedFormData));
    }
    const handleSubmit = async () => {
        try {
            const response = await axios.post('https://c2easybe-production.up.railway.app/c2quick/', formData);
            console.log(response.data);
            localStorage.setItem('calcData', JSON.stringify(response.data.simulationRes.html));
            <Alert severity="success">This is a success alert — check it out!</Alert>
            navigation('resultado');
        } catch (error) {
            console.error('Erro na requisição:', error);
        }
    }

    const savedVencimento = formData.vencimentoAno + '-' + formData.vencimentoMes + '-' + formData.vencimentoDia

    return (
        <Box sx={{
            display: 'flex',

        }}>
            <SideHeader selected="calculo" />
            <ThemeProvider theme={theme}>
                <Container sx={{
                    display: 'flex', flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    backgroundColor: '#fff',
                    borderRadius: '15px',
                    paddingY: '60px',
                    marginY: '20px',
                }}>
                    <Typography variant="h5" sx={{ m: 1, width: '100%' }}>
                        Insira os dados para Simulação
                    </Typography>
                    <NumericFormat
                        value={formData.total}
                        name="total"
                        label="Total"
                        customInput={TextField}
                        thousandSeparator="."
                        decimalSeparator=","
                        prefix="R$ "
                        onValueChange={(values) => {
                            const { floatValue } = values;
                            if (floatValue <= 100000) {
                                let updatedFormData = { ...formData, total: floatValue };
                                setFormData({ ...formData, total: floatValue });
                                localStorage.setItem('formData', JSON.stringify(updatedFormData));
                            } else {
                                window.alert('O valor deve ser menor ou igual a 100.000,00');
                                let updatedFormData = { ...formData, total: 100000 };
                                setFormData({ ...formData, total: 100000 });
                                localStorage.setItem('formData', JSON.stringify(updatedFormData));
                            }
                        }}
                        variant="outlined"
                        sx={{ m: 1, width: '45%' }}
                    />

                    <NumericFormat
                        value={formData.taxa}
                        name="taxa"
                        label="Taxa"
                        customInput={TextField}
                        isNumericString
                        decimalSeparator=","
                        fixedDecimalScale={true}
                        decimalScale={2}
                        suffix="%"
                        onValueChange={(values) => {
                            const { value } = values;
                            let updatedFormData = { ...formData, taxa: value }
                            setFormData({ ...formData, taxa: value });
                            localStorage.setItem('formData', JSON.stringify(updatedFormData));
                        }}
                        variant="outlined"
                        sx={{ m: 1, width: '45%' }}
                    />

                    <TextField
                        name="parcelas"
                        value={formData.parcelas}
                        label="Parcelas"
                        variant="outlined"
                        type="number"
                        onChange={handleChange}
                        sx={{ m: 1, width: '45%' }}
                        inputProps={{ max: 6 }}
                    />
                    <TextField
                        name="carencia"
                        label="Carência"
                        variant="outlined"
                        type="number"
                        value={formData.carencia}
                        onChange={handleChange} sx={{ m: 1, width: '45%' }} />

                    <NumericFormat
                        value={formData.taxaEmissao}
                        name="taxaEmissao"
                        label="Taxa De Emissão"
                        customInput={TextField}
                        thousandSeparator="."
                        decimalSeparator=","
                        prefix="R$ "
                        onValueChange={(values) => {
                            const { floatValue } = values;
                            let updatedFormData = { ...formData, taxaEmissao: floatValue }
                            setFormData({ ...formData, taxaEmissao: floatValue });
                            localStorage.setItem('formData', JSON.stringify(updatedFormData));
                        }}
                        variant="outlined"
                        sx={{ m: 1, width: '45%' }}
                    />
                    <NumericFormat
                        value={formData.custoCapital}
                        name="capital"
                        label="Custo de Capital"
                        customInput={TextField}
                        isNumericString
                        decimalSeparator=","
                        fixedDecimalScale={true}
                        decimalScale={2}
                        suffix="%"
                        onValueChange={(values) => {
                            const { value } = values;
                            let updatedFormData = { ...formData, custoCapital: value }
                            setFormData({ ...formData, custoCapital: value });
                            localStorage.setItem('formData', JSON.stringify(updatedFormData));
                        }}
                        variant="outlined"
                        sx={{ m: 1, width: '45%' }}
                    />
                    <TextField
                        id="date" label="Data" variant="outlined" type="date" sx={{ m: 1, width: '45%' }}

                        value={savedVencimento ? savedVencimento : ''}
                        onChange={(e) => {
                            const date = new Date(e.target.value);
                            let updatedFormData = { ...formData, vencimentoDia: date.getDate(), vencimentoMes: date.getMonth() + 1, vencimentoAno: date.getFullYear() }
                            setFormData({
                                ...formData,
                                vencimentoDia: date.getDate() + 1,
                                vencimentoMes: date.getMonth() + 1,
                                vencimentoAno: date.getFullYear()
                            });
                            localStorage.setItem('formData', JSON.stringify(updatedFormData));
                        }}
                        InputLabelProps={{ shrink: true }} />
                    <Button variant="contained" onClick={handleSubmit} sx={{ m: 1, width: '45%' }}>
                        Calcular
                    </Button>
                </Container>
            </ThemeProvider>
        </Box>
    );
}

export default MainCalc;

import React from "react";
import { GrDocumentUpdate } from "react-icons/gr";
import { FaFolderClosed } from "react-icons/fa6";
import { useDispatch } from 'react-redux';
import { cadastrarEmissorRequest } from "../redux/actions";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    Typography,
    Grid,
    Box
} from "@mui/material";

const DetailsEmissor = ({ selectedEmissor, handleSave, onClose }) => {
    const dispatch = useDispatch();

    const handleTeste = () => {
        const emissorLaqus = {
            cnpj: selectedEmissor.cnpj,
            razaoSocial: selectedEmissor.razaoSocial,
            complemento: selectedEmissor.complemento,
            cep: selectedEmissor.cep,
            logradouro: selectedEmissor.rua,
            numero: selectedEmissor.numero,
            bairro: selectedEmissor.bairro,
            cidade: selectedEmissor.cidade,
            uf: selectedEmissor.estado,
            atividadePrincipal: selectedEmissor.cargo,
            faturamentoMedioMensal12Meses: "2",
            razaoSocialBancoLiquidante: selectedEmissor.banco,
            ispbBancoLiquidante: "0",
            agenciaBancoLiquidante: selectedEmissor.agency,
            contaBancoLiquidante: selectedEmissor.conta,
            telefones: ["00000000000"],

        };
        console.log(emissorLaqus, 'enviado pro teste em sandbox')
        dispatch(cadastrarEmissorRequest(emissorLaqus));
    }
    return (
        <Dialog open={!!selectedEmissor} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>
                Detalhes do Emissor Selecionado
                <Button onClick={onClose} style={{ position: 'absolute', right: 0, top: 0 }}>
                    X
                </Button>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">Razão Social:</Typography>
                        <Typography variant="body1">{selectedEmissor?.razaoSocial}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">CNPJ:</Typography>
                        <Typography variant="body1">{selectedEmissor?.cnpj}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">Representante:</Typography>
                        <Typography variant="body1">{selectedEmissor?.representante}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">Endereço:</Typography>
                        <Box>
                            <Typography variant="body1" component="span">
                                CEP: {selectedEmissor?.cep}
                                <br />
                            </Typography>
                            <Typography variant="body1" component="span">
                                Rua: {selectedEmissor?.rua}
                                <br />
                            </Typography>
                            <Typography variant="body1" component="span">
                                Bairro: {selectedEmissor?.bairro}
                                <br />
                            </Typography>
                            <Typography variant="body1" component="span">
                                Cidade: {selectedEmissor?.cidade}
                                <br />
                            </Typography>
                            <Typography variant="body1" component="span">
                                UF: {selectedEmissor?.estado}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">Avalistas:</Typography>
                        <Box>
                            {selectedEmissor?.avalistas.map((avalista, index) => (
                                <Typography key={index} variant="body1" component="div">
                                    Avalista {index + 1}. {avalista.aNome}, {avalista.aCpf}
                                </Typography>
                            ))}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">Email:</Typography>
                        <Typography variant="body1">{selectedEmissor?.email}</Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <Box textAlign="center" mt={2}>
                <Button variant="contained" onClick={handleTeste}>
                    Cadastrar Emissor
                </Button>
            </Box>
            <Box textAlign="center" mt={2}>
                <Button variant="contained" onClick={handleSave}>
                    Selecionar Emissor
                </Button>
            </Box>
        </Dialog>
    );
}

export default DetailsEmissor;

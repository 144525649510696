import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Emissors from "../components/Emissors";
import DevedorSolidario from "../components/DevedorSolidario";
import { BsArrowLeft } from "react-icons/bs";
import { formatCnpj, formatCpf, formatDocument } from "../Modals/UpdateEmissor";
import { Box, Button, Container, FormControl, FormGroup, FormLabel, Select, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { cadastrarEmissorRequest } from "../redux/actions";

const Emissor = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user'));
    const emissor = JSON.parse(localStorage.getItem('emissor'));
    const storedData = JSON.parse(localStorage.getItem('notaComercial'));

    const [dadosBancarios, setDadosBancarios] = useState(storedData ? storedData.dadosBancarios : '');
    const [conta, setConta] = useState(emissor ? emissor.conta : '');
    const [agency, setAgency] = useState(emissor ? emissor.agency : '');
    const [banks, setBanks] = useState([]);
    const [selectedBank, setSelectedBank] = useState(emissor ? emissor.banco : '');
    const [bankNumber, setBankNumber] = useState(emissor ? emissor.bankNumber : '');
    const [devedor, setDevedor] = useState(true);
    const [fiel, setFiel] = useState(emissor ? emissor.fielDepositario : false);
    const [avalista, setAvalista] = useState(false);
    const [dRazao, setDRazao] = useState(emissor ? emissor.razaoSocial : '');
    const [dCnpj, setDCnpj] = useState(emissor ? emissor.cnpj : '');
    const [dCep, setDCep] = useState(emissor ? emissor.cep : '');
    const [dRua, setDRua] = useState(emissor ? emissor.rua : '');
    const [dCidade, setDCidade] = useState(emissor ? emissor.cidade : '');
    const [dEstado, setDEstado] = useState(emissor ? emissor.estado : '');
    const [dNumero, setDNumero] = useState(emissor ? emissor.numero : '');
    const [dBairro, setDBairro] = useState(emissor ? emissor.bairro : '');
    const [dComplemento, setDComplemento] = useState(emissor ? emissor.complemento : '');
    const [dEmail, setDEmail] = useState(emissor ? emissor.email : '');
    const [representante, setRepresentante] = useState(emissor ? emissor.representante : '');
    const [dCpf, setDCpf] = useState(emissor ? emissor.cpf : '');
    const [cargo, setCargo] = useState(emissor ? emissor.cargo : '');

    const [dAdress, setDAdress] = useState('');
    const [fName, setFName] = useState(emissor ? emissor.fielDepositario.nome : '');
    const [fOcupacao, setFOcupacao] = useState(emissor ? emissor.fielDepositario.ocupacao : '');
    const [fCep, setFCep] = useState(emissor ? emissor.fielDepositario.cep : '');
    const [fRua, setFRua] = useState(emissor ? emissor.fielDepositario.rua : '');
    const [fCidade, setFCidade] = useState(emissor ? emissor.fielDepositario.cidade : '');
    const [fEstado, setFEstado] = useState(emissor ? emissor.fielDepositario.estado : '');
    const [fNumero, setFNumero] = useState(emissor ? emissor.fielDepositario.numero : '');
    const [fComplemento, setFComplemento] = useState(emissor ? emissor.fielDepositario.complemento : '');
    const [fCpf, setFCpf] = useState(emissor ? emissor.fielDepositario.cpf : '');
    const [fCnpj, setFCnpj] = useState(emissor ? emissor.fielDepositario.cnpj : '');
    const [fRg, setFRg] = useState(emissor ? emissor.fielDepositario.rg : '');
    const [fEmail, setFEmail] = useState(emissor ? emissor.fielDepositario.email : '');
    const [avalistas, setAvalistas] = useState(emissor ? emissor.avalistas : [{ aNome: '', aCpf: '', aEmail: '', aCep: '', aRua: '', aCidade: '', aEstado: '', aNumero: '', aComplemento: '', aProfissao: '', aNacionalidade: '', aEstadoCivil: '', aDataNascimento: '' }]);
    const [meioPagamento, setMeioPagamento] = useState('[X] Transferência Eletrônica Disponível (TED)');
    const [erroValidacao, setErroValidacao] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [tipo, setTipo] = useState("PF"); // Inicialmente, define como pessoa física (PF)
    const [solidario, setSolidario] = useState(false);
    const [cadastrados, setCadastrados] = useState(true);
    const [devedorInfo, setDevedorInfo] = useState({});
    const handleExpand = () => {
        setIsExpanded(!isExpanded);
    };
    const updateFormData = (newData) => {
        setDevedorInfo(newData);
    };
    useEffect(() => {
        handleExpand()

    }, [])
    //const [loading, setLoading] = useState(false);

    const keywordsToPrioritize = ['caixa eco', 'itaú uni', 'bco do brasil', 'bradesco s', 'santander', 'original', 'banco inter', 'bdigital'];

    const sortedBanks = [...banks].sort((a, b) => {
        const bankNameA = a.name ? a.name.toLowerCase() : '';
        const bankNameB = b.name ? b.name.toLowerCase() : '';

        const priorityA = getPriority(bankNameA);
        const priorityB = getPriority(bankNameB);

        if (priorityA !== priorityB) {
            return priorityA - priorityB; // Coloque o banco com prioridade maior primeiro
        } else {
            return bankNameA.localeCompare(bankNameB); // Ordem alfabética padrão
        }
    });
    const toggleTipo = () => {
        setTipo(tipo === "PF" ? "PJ" : "PF");
    };
    const toggleSolidario = () => {
        setSolidario(!solidario);
    }
    function getPriority(name) {
        for (let i = 0; i < keywordsToPrioritize.length; i++) {
            if (name.includes(keywordsToPrioritize[i])) {
                return i;
            }
        }
        return keywordsToPrioritize.length;
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        fetch('https://brasilapi.com.br/api/banks/v1')
            .then(response => response.json())
            .then(data => setBanks(data))
            .catch(error => console.error('Ocorreu um erro ao buscar os bancos:', error));
        setDadosBancarios(`Banco: ${selectedBank}-${bankNumber}/Agencia: ${agency}/Conta: ${conta}`);
    }, []);

    const handleBankChange = (event) => {
        const selectedBankName = event.target.value;
        setSelectedBank(selectedBankName);
        const selectedBankData = banks.find(bank => bank.name === selectedBankName);

        if (selectedBankData) {
            setBankNumber(selectedBankData.code);
        }
        else if (selectedBankName === 'BNK Digital') {
            setBankNumber(547);
        }
        else {
            setBankNumber('');
        }
    };

    const handleBankNumberChange = (event) => {
        const bankNumber = event.target.value;
        setBankNumber(bankNumber);

        const selectedBankData = banks.find(bank => bank.code == bankNumber);

        if (selectedBankData) {
            setSelectedBank(selectedBankData.name);
        } else {
            setSelectedBank('');
        }
    };

    const handleSearch = async (cep) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
            const data = response.data;

            setFRua(data.logradouro || "");
            setFNumero("");
            setFComplemento("");
            setFCidade(data.localidade || "");
            setFEstado(data.uf || "");
        } catch (error) {
            console.error('Erro ao buscar endereço:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCepChange = (e) => {
        const newCep = e?.target.value?.replace(/\D/g, "");
        setFCep(newCep);

        if (newCep.length === 8) {
            handleSearch(newCep);
        }
    };

    const toggleDevedorVisibility = () => {
        setDevedor(!devedor);
    };

    const handleAvalistaChange = (e, index, field) => {
        const newAvalistas = [...avalistas];
        newAvalistas[index][field] = e.target.value;
        setAvalistas(newAvalistas);
        console.log(newAvalistas);
        console.log(avalistas);
    };

    const removeAvalista = (indexToRemove) => {
        const updatedAvalistas = [...avalistas];
        updatedAvalistas.splice(indexToRemove, 1); // Remove o avalista pelo índice
        setAvalistas(updatedAvalistas);
    };

    const toggleFielVisibility = () => {
        setFiel(!fiel);
    };

    const toggleCadastrados = () => {
        setCadastrados(!cadastrados);
    };

    const toggleAvalistaVisibility = () => {
        setAvalista(!avalista);
    };

    const handleSearch2 = async () => {
        try {
            setIsLoading2(true);
            const response = await axios.get(`https://viacep.com.br/ws/${dCep}/json/`);
            setDAdress(response.data);
            setDBairro(response.data.bairro);
            setDCidade(response.data.localidade);
            setDEstado(response.data.uf);
            setDRua(response.data.logradouro);
        } catch (error) {
            console.error('Error fetching address:', error);
        } finally {
            setIsLoading2(false);
        }
    };

    const addAvalista = () => {
        setAvalistas([...avalistas, { aNome: '', aCpf: '', aEmail: '' }]);
    };
    const validateFields = (data) => {
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const value = data[key];
                if (key === 'complemento') {
                    continue;
                }
                if (key === 'garantia') {
                    continue;
                }
                if (key === 'gTipo') {
                    continue;
                }
                if (key === 'gNatureza') {
                    continue;
                }
                if (key === 'gDescricao') {
                    continue;
                }
                if (key === 'gDocumento') {
                    continue;
                }
                if (key === 'gProprietario') {
                    continue;
                }
                if (key === 'gQuantidade') {
                    continue;
                }
                if (key === 'gValor') {
                    continue;
                }
                if (!value || (typeof value === 'string' && value === '')) {
                    return `O campo ${key} não pode estar vazio.`;
                }

            }
        }
        return null; // Todos os campos estão preenchidos
    };

    const sendInfos = async () => {
        console.log(avalistas, 'avalistosaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa');
        const dados = {
            userRef: 'c2quick',
            razaoSocial: dRazao,
            cnpj: dCnpj,
            email: dEmail,
            cpf: dCpf,
            representante,
            cargo,
            banco: selectedBank,
            bankNumber,
            conta,
            agency,
            cep: dCep,
            rua: dRua,
            numero: dNumero,
            bairro: dBairro,
            cidade: dCidade,
            estado: dEstado,
            complemento: dComplemento,
            fielDepositario: {
                nome: fName,
                ocupacao: fOcupacao,
                cep: fCep,
                rua: fRua,
                numero: fNumero,
                complemento: fComplemento,
                cidade: fCidade,
                estado: fEstado,
                cpf: fCpf,
                rg: fRg,
                email: fEmail
            },
            devedorSolidario: devedorInfo,
            avalistas,
            meioPagamento,
        }
        const emissorLaqus = {
            cnpj: dCnpj,
            razaoSocial: dRazao,
            complemento: dComplemento,
            cep: dCep,
            logradouro: dRua,
            numero: dNumero,
            bairro: dBairro,
            cidade: dCidade,
            uf: dEstado,
            atividadePrincipal: cargo,
            faturamentoMedioMensal12Meses: "",
            razaoSocialBancoLiquidante: selectedBank,
            ispbBancoLiquidante: "",
            agenciaBancoLiquidante: agency,
            contaBancoLiquidante: conta,
            telefones: ""
        };
        const resultadoValidacao = validateFields(dados);
        if (resultadoValidacao) {
            setErroValidacao(resultadoValidacao);
            return;
        }
        setErroValidacao(null);

        const response = await axios.post('https://c2easybe-production.up.railway.app/emissor', dados, {
            headers: {
                'Content-Length': JSON.stringify(dados).length,
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 201) {
            console.log('Emissor criado com sucesso!');
            dispatch(cadastrarEmissorRequest(emissorLaqus));
            console.log(emissorLaqus, 'dados enviou pro redux');
        }
        localStorage.setItem('emissorPreenchido', true);
        localStorage.setItem('emissor', JSON.stringify(dados));
        window.alert('Emissor Cadastrado!');
    }

    const handleSearchAva = async (cep, index) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
            const data = response.data;

            const newAvalistas = [...avalistas];
            const currentAvalista = newAvalistas[index];

            currentAvalista.aRua = data.logradouro || "";
            currentAvalista.aNumero = "";
            currentAvalista.aBairro = data.bairro || "";
            currentAvalista.aCidade = data.localidade || "";
            currentAvalista.aEstado = data.uf || "";

            setAvalistas(newAvalistas);
        } catch (error) {
            console.error('Erro ao buscar endereço:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCepChangeAva = (e, index, field) => {
        const newCep = e?.target.value?.replace(/\D/g, "");
        const newAvalistas = [...avalistas];
        newAvalistas[index][field] = e.target.value;
        setAvalistas(newAvalistas);

        if (newCep.length === 8) {
            handleSearchAva(newCep, index);
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
        }}>
            <Header selected={'emissor'} />
            <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Button onClick={toggleCadastrados}>
                    Cadastrar novo emissor
                </Button>
                {
                    cadastrados ?
                        <Emissors />
                        :
                        <div className="mainDoc22">
                            <FormControl className="formDoc1">
                                <div className="divTitleDoc33" onClick={() => { toggleCadastrados() }}>
                                    <Typography variant="h5"><BsArrowLeft style={{ marginRight: '0.5rem' }} />Cadastrar novo emissor</Typography>
                                </div>
                                <FormLabel htmlFor="" className="labelInputsDoc">
                                    Razão Social do Emissor:
                                </FormLabel>
                                <TextField
                                    type="text"
                                    placeholder="Razão Social"
                                    className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                    value={dRazao}
                                    onChange={(e) => { setDRazao(e.target.value) }}
                                    maxLength={120} />

                                <FormLabel htmlFor="" className="labelInputsDoc">
                                    CNPJ:
                                </FormLabel>
                                <TextField
                                    type="text"
                                    placeholder="CNPJ"
                                    className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                    value={formatCnpj(dCnpj)}
                                    onChange={(e) => { setDCnpj(e.target.value) }}
                                    maxLength={18} />

                                <FormLabel htmlFor="" className="labelInputsDoc">
                                    CEP:
                                </FormLabel>
                                <TextField
                                    mask="99999-999"
                                    maskChar=""
                                    type="text"
                                    placeholder="CEP"
                                    className={`inputsDoc`}
                                    value={dCep}
                                    onChange={(e) => { setDCep(e.target.value) }}
                                    onBlur={handleSearch2}
                                />

                                <FormLabel htmlFor="" className="labelInputsDoc">
                                    Número:
                                </FormLabel>
                                <TextField
                                    type="text"
                                    placeholder="Número"
                                    className={`inputsDoc`}
                                    value={dNumero}
                                    onChange={(e) => { setDNumero(e.target.value) }}
                                />

                                <FormLabel htmlFor="" className="labelInputsDoc">
                                    Complemento:
                                </FormLabel>
                                <TextField
                                    type="text"
                                    placeholder="Complemento"
                                    className={`inputsDoc`}
                                    value={dComplemento}
                                    onChange={(e) => { setDComplemento(e.target.value) }}
                                />

                                {isLoading2 ? (
                                    <p className="loading">Carregando...</p>
                                ) : (
                                    <div>

                                        <FormLabel htmlFor="" className="labelInputsDoc">
                                            Rua:
                                        </FormLabel>
                                        <TextField
                                            type="text"
                                            placeholder="Rua"
                                            className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                            value={dRua}
                                            onChange={(e) => { setDRua(e.target.value) }}
                                        />



                                        <FormLabel htmlFor="" className="labelInputsDoc">
                                            Bairro:
                                        </FormLabel>
                                        <TextField
                                            type="text"
                                            placeholder="Complemento"
                                            className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                            value={dBairro}
                                            onChange={(e) => { setDBairro(e.target.value) }}
                                        />



                                        <FormLabel htmlFor="" className="labelInputsDoc">
                                            Cidade:
                                        </FormLabel>
                                        <TextField
                                            placeholder="Cidade"
                                            type="text"
                                            className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                            value={dCidade}
                                            onChange={(e) => { setDCidade(e.target.value) }}
                                        />


                                        <FormLabel htmlFor="" className="labelInputsDoc">
                                            Estado:
                                        </FormLabel>
                                        <TextField
                                            type="text"
                                            placeholder="Estado"
                                            className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                            value={dEstado}
                                            onChange={(e) => { setDEstado(e.target.value) }}
                                        />

                                    </div>)
                                }
                                <FormLabel htmlFor="" className="labelInputsDoc">
                                    e-mail:
                                </FormLabel>
                                <TextField
                                    type="text"
                                    placeholder="Email"
                                    className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                    value={dEmail}
                                    onChange={(e) => { setDEmail(e.target.value) }}
                                    maxLength={40} />

                                <FormLabel htmlFor="" className="labelInputsDoc">
                                    Representante Legal
                                </FormLabel>
                                <TextField
                                    type="text"
                                    placeholder="Representante Legal"
                                    className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                    value={representante}
                                    onChange={(e) => { setRepresentante(e.target.value) }}
                                    maxLength={40} />

                                <FormLabel htmlFor="" className="labelInputsDoc">
                                    CPF
                                </FormLabel>
                                <TextField
                                    type="text"
                                    placeholder="CPF"
                                    className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                    value={formatCpf(dCpf)}
                                    onChange={(e) => { setDCpf(e.target.value) }}
                                    maxLength={14} />

                                <FormLabel htmlFor="" className="labelInputsDoc">
                                    Cargo
                                </FormLabel>
                                <TextField
                                    type="text"
                                    placeholder="Cargo"
                                    className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                    value={cargo}
                                    onChange={(e) => { setCargo(e.target.value) }}
                                    maxLength={40} />

                                <FormLabel htmlFor="" className="labelInputsDoc">
                                    Dados Bancários
                                </FormLabel>
                                <Select value={selectedBank} onChange={handleBankChange} className="selectDoc">
                                    <option value="" className="optionInputsDoc">Selecione um banco</option>
                                    <option value="BNK Digital" className="optionInputsDoc">BNK Digital</option>
                                    {sortedBanks.map((bank, index) => (
                                        <option key={index} value={bank.name} className="optionInputsDoc">
                                            {bank.name}
                                        </option>
                                    ))}
                                </Select>


                                <FormLabel htmlFor="" className="labelInputsDoc">Numero do Banco</FormLabel>
                                <TextField
                                    type='text'
                                    name="Número do banco"
                                    placeholder="Selecione um banco"
                                    className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                    value={bankNumber}
                                    onChange={handleBankNumberChange}
                                    maxLength={20}
                                />

                                <FormLabel htmlFor="" className="labelInputsDoc">
                                    Agência:
                                </FormLabel>
                                <TextField
                                    placeholder="12345"
                                    type="text"
                                    className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                    value={agency}
                                    onChange={(e) => { setAgency(e.target.value) }}
                                    maxLength={8} />

                                <FormLabel htmlFor="" className="labelInputsDoc">
                                    Conta:
                                </FormLabel>
                                <TextField
                                    placeholder="12345"
                                    type="text"
                                    className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                    value={conta}
                                    onChange={(e) => { setConta(e.target.value) }}
                                    maxLength={10} />

                                <FormLabel className="labelInputsDoc">Meio de Pagamento</FormLabel>
                                <Select name="meioDePagamento" value={meioPagamento} onChange={(e) => { setMeioPagamento(e.target.value) }} className="selectDoc">
                                    <option value={`[X] Transferência Eletrônica Disponível (TED)`}>Transferência Eletrônica Disponível (TED)</option>
                                    <option value={`[X] Dação em Pagamento`}>Dação em Pagamento</option>
                                </Select>


                                <div className="newButtonEm" onClick={toggleAvalistaVisibility}>
                                    {avalista ? "Ocultar Cadastro de Avalista" : "Exibir Cadastro de Avalista +"}
                                </div >
                                {
                                    avalista ?
                                        <div className=''>
                                            {avalistas.map((avalista, index) => (
                                                <FormGroup key={index} className="mainAvalista">
                                                    <Typography variant="h6">Avalista {index + 1}</Typography>

                                                    <FormLabel htmlFor="" className="labelInputsDoc" style={{ marginTop: '2vh' }}>
                                                        Nome:
                                                    </FormLabel>
                                                    <TextField
                                                        type="text"
                                                        className={`inputsDocAva`}
                                                        value={avalista.aNome}
                                                        onChange={(e) => handleAvalistaChange(e, index, 'aNome')}
                                                        maxLength={40}
                                                    />


                                                    <FormLabel htmlFor="" className="labelInputsDoc" style={{ marginTop: '2vh' }}>
                                                        CPF/CNPJ:
                                                    </FormLabel>
                                                    <TextField
                                                        type="text"
                                                        className={`inputsDocAva`}
                                                        value={formatDocument(avalista.aCpf)}
                                                        onChange={(e) => handleAvalistaChange(e, index, 'aCpf')}
                                                        maxLength={19}
                                                    />


                                                    <FormLabel htmlFor="" className="labelInputsDoc">
                                                        Email:
                                                    </FormLabel>
                                                    <TextField
                                                        type="text"
                                                        className={`inputsDocAva`}
                                                        value={avalista.aEmail}
                                                        onChange={(e) => handleAvalistaChange(e, index, 'aEmail')}
                                                    />

                                                    <FormLabel htmlFor="" className="labelInputsDoc">
                                                        País de Nascimento:
                                                    </FormLabel>
                                                    <TextField
                                                        type="text"
                                                        className={`inputsDocAva`}
                                                        value={avalista.aNascimento}
                                                        onChange={(e) => handleAvalistaChange(e, index, 'aNascimento')}
                                                        maxLength={40}
                                                    />

                                                    <FormLabel htmlFor="" className="labelInputsDoc">
                                                        Estado Civil:
                                                    </FormLabel>
                                                    <Select
                                                        className={`selectDoc`}

                                                        value={avalista.aEstadoCivil}
                                                        onChange={(e) => handleAvalistaChange(e, index, 'aEstadoCivil')}
                                                    >
                                                        <option value="solteiro">Solteiro</option>
                                                        <option value="casado">Casado</option>
                                                        <option value="divorciado">Divorciado</option>
                                                        <option value="viuvo">Viúvo</option>
                                                        {/* Adicione mais opções conforme necessário */}
                                                    </Select>


                                                        <FormLabel htmlFor="" className="labelInputsDoc">
                                                            Profissão:
                                                        </FormLabel>
                                                        <TextField
                                                            type="text"
                                                            className={`inputsDocAva`}
                                                            value={avalista.aProfissao}
                                                            onChange={(e) => handleAvalistaChange(e, index, 'aProfissao')}
                                                        />

                                                        <FormLabel htmlFor="" className="labelInputsDoc">
                                                            Data de Nascimento:
                                                        </FormLabel>
                                                        <TextField
                                                            type="date"
                                                            className={`inputsDocAva`}
                                                            value={avalista.aDataNascimento}
                                                            onChange={(e) => handleAvalistaChange(e, index, 'aDataNascimento')}
                                                            maxLength={40}
                                                        />

                                                        <FormLabel htmlFor="" className="labelInputsDoc">
                                                            CEP:
                                                        </FormLabel>
                                                        <TextField
                                                            type="text"
                                                            className={`inputsDocAva`}
                                                            value={avalista.aCep}
                                                            onChange={(e) => handleCepChangeAva(e, index, 'aCep')}
                                                        />

                                                        <FormLabel htmlFor="" className="labelInputsDoc">
                                                            Rua:
                                                        </FormLabel>
                                                        <TextField
                                                            type="text"
                                                            className={`inputsDocAva`}
                                                            value={avalista.aRua}
                                                            onChange={(e) => handleAvalistaChange(e, index, 'aRua')}
                                                            maxLength={14}
                                                        />

                                                        <FormLabel htmlFor="" className="labelInputsDoc">
                                                            Número:
                                                        </FormLabel>
                                                        <TextField
                                                            type="text"
                                                            className={`inputsDocAva`}
                                                            value={avalista.aNumero}
                                                            onChange={(e) => handleAvalistaChange(e, index, 'aNumero')}
                                                            maxLength={14}
                                                        />

                                                        <FormLabel htmlFor="" className="labelInputsDoc">
                                                            Bairro:
                                                        </FormLabel>
                                                        <TextField
                                                            type="text"
                                                            className={`inputsDocAva`}
                                                            value={avalista.aBairro}
                                                            onChange={(e) => handleAvalistaChange(e, index, 'aBairro')}
                                                            maxLength={14}
                                                        />
                                                        <FormLabel htmlFor="" className="labelInputsDoc">
                                                            Cidade:
                                                        </FormLabel>
                                                        <TextField
                                                            type="text"
                                                            className={`inputsDocAva`}
                                                            value={avalista.aCidade}
                                                            onChange={(e) => handleAvalistaChange(e, index, 'aCidade')}
                                                            maxLength={14}
                                                        />
                                                        <FormLabel htmlFor="" className="labelInputsDoc">
                                                            Estado:
                                                        </FormLabel>
                                                        <TextField
                                                            type="text"
                                                            className={`inputsDocAva`}
                                                            value={avalista.aEstado}
                                                            onChange={(e) => handleAvalistaChange(e, index, 'aEstado')}
                                                            maxLength={14}
                                                        />
                                                        <div className="addButton2" onClick={() => removeAvalista(0)}>Remover Avalista</div>
                                                    </FormGroup>
                                            ))}
                                                    <div className="addButton" onClick={addAvalista}>
                                                        + Adicionar Avalista
                                                    </div>
                                                    </div>

                                                    : <br />}
                                                    <div onClick={toggleFielVisibility} className="newButtonEm">
                                                        {fiel ? "Ocultar Cadastro de Fiel Depositário" : "Exibir Cadastro de Fiel Depositário +"}
                                                    </div>
                                                    {fiel ?
                                                        <form className="mainAvalista">
                                                            <div className="divTitleDoc">
                                                                <Typography variant="h5">FIEL DEPOSITÁRIO</Typography>

                                                            </div>
                                                            <div type="button" style={{
                                                                width: '100%',
                                                                padding: '10px',
                                                                cursor: 'pointer',
                                                                backgroundColor: '#2C3E50',
                                                                color: 'white',
                                                                textAlign: 'center',
                                                                borderRadius: '5px',
                                                                marginBottom: '10px'
                                                            }} onClick={toggleTipo}>
                                                                {tipo === "PF" ? "Pessoa Física (CPF)" : "Pessoa Jurídica (CNPJ)"}
                                                            </div>
                                                            {tipo === "PF" ? (
                                                                <div>
                                                                    <FormLabel htmlFor="" className="labelInputsDoc">
                                                                        Nome:
                                                                    </FormLabel>
                                                                    <TextField
                                                                        type="text"
                                                                        className={`inputsDoc`}
                                                                        value={fName}
                                                                        onChange={(e) => { setFName(e.target.value) }}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <FormLabel htmlFor="" className="labelInputsDoc">
                                                                        Razão Social:
                                                                    </FormLabel>
                                                                    <TextField
                                                                        type="text"
                                                                        className={`inputsDoc`}
                                                                        value={fName} // Você precisa criar o estado fCnpj
                                                                        onChange={(e) => { setFName(e.target.value) }}
                                                                    />
                                                                </div>
                                                            )}

                                                            <div>
                                                                <FormLabel htmlFor="" className="labelInputsDoc">
                                                                    Ocupação:
                                                                </FormLabel>
                                                                <TextField
                                                                    type="text"
                                                                    className={`inputsDoc`}
                                                                    value={fOcupacao}
                                                                    onChange={(e) => { setFOcupacao(e.target.value) }}
                                                                    maxLength={40} />
                                                            </div>
                                                            <div className="divInputsDoc" style={{ width: '100%', display: 'flex', justifyContent: 'start' }}>
                                                                <FormLabel htmlFor="" className="labelInputsDoc">
                                                                    CEP:
                                                                </FormLabel>
                                                                <TextField
                                                                    mask="99999-999"
                                                                    maskChar=""
                                                                    type="text"
                                                                    className={`inputsDoc`}
                                                                    value={fCep}
                                                                    onChange={handleCepChange}
                                                                    style={{ width: '45%' }}
                                                                />
                                                            </div>
                                                            {isLoading ? (
                                                                <p className="loading">Carregando...</p>
                                                            ) : (
                                                                <div className="divFull">
                                                                    <div className="rowDiv">

                                                                        <FormLabel htmlFor="" className="labelInputsDoc">
                                                                            Rua:
                                                                        </FormLabel>
                                                                        <TextField
                                                                            type="text"
                                                                            className={`inputsDoc`}
                                                                            value={fRua}
                                                                            onChange={(e) => setFRua(e.target.value)}
                                                                            maxLength={40}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <FormLabel htmlFor="" className="labelInputsDoc">
                                                                            Número:
                                                                        </FormLabel>
                                                                        <TextField
                                                                            type="text"
                                                                            className={`inputsDoc`}
                                                                            value={fNumero}
                                                                            onChange={(e) => setFNumero(e.target.value)}
                                                                            maxLength={40}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <FormLabel htmlFor="" className="labelInputsDoc">
                                                                            Complemento:
                                                                        </FormLabel>
                                                                        <TextField
                                                                            type="text"
                                                                            className={`inputsDoc`}
                                                                            value={fComplemento}
                                                                            onChange={(e) => setFComplemento(e.target.value)}
                                                                            maxLength={40}
                                                                        />

                                                                    </div>
                                                                </div>

                                                            )}
                                                            <div>
                                                                <FormLabel htmlFor="" className="labelInputsDoc">
                                                                    Cidade:
                                                                </FormLabel>
                                                                <TextField
                                                                    type="text"
                                                                    className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                                                    value={fCidade}
                                                                    onChange={(e) => setFCidade(e.target.value)}
                                                                    maxLength={40}
                                                                />
                                                            </div>
                                                            <div>
                                                                <FormLabel htmlFor="" className="labelInputsDoc">
                                                                    Estado:
                                                                </FormLabel>
                                                                <TextField
                                                                    type="text"
                                                                    className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                                                    value={fEstado}
                                                                    onChange={(e) => setFEstado(e.target.value)}
                                                                    maxLength={40}
                                                                />
                                                            </div>
                                                            {tipo === "PF" ? (
                                                                <div>
                                                                    <FormLabel htmlFor="" className="labelInputsDoc">
                                                                        CPF:
                                                                    </FormLabel>
                                                                    <TextField
                                                                        type="text"
                                                                        className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                                                        value={formatCpf(fCpf)}
                                                                        onChange={(e) => { setFCpf(e.target.value) }}
                                                                        maxLength={14}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <FormLabel htmlFor="" className="labelInputsDoc">
                                                                        CNPJ:
                                                                    </FormLabel>
                                                                    <TextField
                                                                        type="text"
                                                                        className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                                                        value={formatCnpj(fCnpj)} // Você precisa criar o estado fCnpj
                                                                        onChange={(e) => { setFCnpj(e.target.value) }}
                                                                        maxLength={18}
                                                                    />
                                                                </div>
                                                            )}

                                                            <FormLabel htmlFor="" className="labelInputsDoc">
                                                                e-mail:
                                                            </FormLabel>
                                                            <TextField
                                                                type="text"
                                                                className={`inputsDoc ${isExpanded ? 'expandedDoc' : 'expandedDoc'}`}
                                                                value={fEmail}
                                                                onChange={(e) => { setFEmail(e.target.value) }}
                                                                maxLength={40} />

                                                        </form>
                                                        : <br />}
                                                    {/* <div onClick={toggleSolidario} className="newButtonEm">
                                    {solidario ? "Ocultar Cadastro de Devedor Solidário" : "Exibir Cadastro de Devedor Solidário  +"}
                                </div>
                                {solidario ?
                                    <DevedorSolidario updateFormData={updateFormData} />
                                    :
                                    <br />} */}

                                                    <div className="buttonEmissor1" >
                                                        <div onClick={() => setCadastrados(false)} className="emissorBack" > Voltar</div>
                                                        <div onClick={sendInfos} className="emissorSend">Cadastrar Emissor</div>
                                                    </div>
                                                    {erroValidacao && (
                                                        <div className="mensagemErro">
                                                            Erro de validação: {erroValidacao}
                                                        </div>
                                                    )}</FormControl>
                        </div>}
                            </Container >

                        </Box >
    )
}

                export default Emissor;
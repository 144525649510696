import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Typography, IconButton } from '@mui/material';
import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';
import { MdLockOutline, MdPersonOutline } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import background from '../images/backgroundWhite.png';
import logo from '../images/logoboa.png';
import { Image } from '@mui/icons-material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const authenticateUserInternal = async (authEmail, authPassword, endpoint, navigate, dispatch) => {
    try {
        console.log('Authenticating user internally...');
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: authEmail,
                password: authPassword,
            }),
        });

        const json = await response.json();

        if (response.status === 201) {
            console.log('Authentication successful!');
            localStorage.setItem('userToken', json.token);
            localStorage.setItem('user', JSON.stringify(json));
            localStorage.setItem('calcData', false);

            navigate('/home');
        } else {
            console.log('Authentication failed.');
            window.alert('Informações Incorretas. Revise e tente novamente.');
        }
    } catch (error) {
        console.error(error);
        window.alert('Informações Incorretas. Revise e tente novamente.');
    }
};

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(window.location.search);
    const emailParam = queryParams.get('emailParam');
    const senhaParam = queryParams.get('senhaParam');

    useEffect(() => {
        console.log('useEffect triggered', emailParam, senhaParam);
        if (emailParam && senhaParam) {
            console.log('Checking URL parameters for login...');
            authenticateUserInternal(
                emailParam,
                senhaParam,
                'https://c2easybe-production.up.railway.app/master/authenticate',
                navigate,
                dispatch
            );
        }
    }, [emailParam, senhaParam, navigate, dispatch]);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleLogin = async () => {
        console.log('Handling login...');
        if (emailParam && senhaParam) {
            authenticateUserInternal(
                emailParam,
                senhaParam,
                'https://c2easybe-production.up.railway.app/master/authenticate',
                navigate,
                dispatch
            );
        } else {
            authenticateUserInternal(
                email,
                password,
                'https://c2easybe-production.up.railway.app/master/authenticate',
                navigate,
                dispatch
            );
        }
    };

    return (
        <div
            style={{
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center', // Centraliza verticalmente
                padding: '0 20px',
            }}
        >

            <div style={{
                width: '40%',
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Typography variant="h4" className="title" style={{
                    fontWeight: 'bold',
                    paddingBottom: 20,
                    paddingInline: 60,
                }}>
                    Bem Vindo
                </Typography>
                <Typography variant="body1" style={{
    paddingBottom: 20,
    fontSize: 20,
    paddingInline: 60,
}}>
    Faça login e desfrute de uma experiência{' '}
    <Typography component="span" style={{
        fontSize: 20,
        fontStyle: 'italic',
        display: 'inline',
    }}>
        Simples
    </Typography>{' '}
    e{' '}
    <Typography component="span" style={{
        fontSize: 20,
        fontStyle: 'italic',
        display: 'inline',
    }}>
        Segura
    </Typography>{' '}
    na emissão de Notas Comerciais
</Typography>

                <div>

                    <TextField
                        variant="outlined"
                        label="E-mail"
                        margin="dense"
                        maxLength={40}
                        fullWidth
                        value={email}
                        placeholder="Insira seu e-mail"
                        onChange={(e) => setEmail(e.target.value)}
                        style={{
                            marginInline: 20,
                        }}
                    />

                    <TextField
                        variant="outlined"
                        label="Senha"
                        fullWidth
                        margin="dense"
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Insira sua senha"
                        type={showPassword ? 'text' : 'password'}
                        style={{
                            marginInline: 20,
                        }}
                    />

                    <Button variant="contained" onClick={handleLogin} fullWidth style={{
                        marginInline: 20,
                    }}>
                        Entrar
                    </Button>
                    <Typography style={{

                        marginTop: 20,
                        textAlign: 'center',
                    }}>
                        <IconButton
                            aria-label="whatsapp"
                            onClick={() => {
                                // Adicione a lógica para abrir o link do WhatsApp aqui
                                window.open('https://wa.me/seu-numero-de-whatsapp', '_blank');
                            }}
                        >
                            <WhatsAppIcon />
                        </IconButton>
                        (11) 9.9479-6599
                    </Typography>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: 20,
                        paddingInline: 80,
                    }}>

                    <Typography >
                        contato@c2ss.com.br
                    </Typography>
                    <Typography>
                        www.c2ss.com.br
                    </Typography>
                    </div>
                </div>
                <Typography variant="body2" style={{ fontSize: '12px', textAlign: 'end' }}>
                    v 1.0
                </Typography>
            </div>
            <div>
                <img src={logo} alt="C2Quick Logo" style={{
                    width: '100vh',
                    height: '60vh',
                }} />
            </div>
        </div>
    );
};

export default Login;

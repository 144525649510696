import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaTrashCan } from "react-icons/fa6";
import UpdateInvestor from '../Modals/UpdateInvestor';
import { GrDocumentUpdate } from "react-icons/gr";
import { BsEye } from "react-icons/bs";
import InvestidorModal from "../Modals/InvestidorModal";
import { FaEdit } from "react-icons/fa";
import { Table, TableHead, TableCell, TableRow, TableBody, Container, TableContainer, Paper, Tooltip, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, Button, DialogActions } from '@mui/material';
import { Delete, Edit, VisibilityOutlined } from "@mui/icons-material";
import { red } from "@mui/material/colors";

const Investidores = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);
    const [selectedInvestidor, setSelectedInvestidor] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [deleteError, setDeleteError] = useState(false);
    const [investidores, setInvestidores] = useState([]);
    const [modalInvestorIsOpen, setModalInvestorIsOpen] = useState(false);
    const [detailsInvestor, setDetailsInvestor] = useState(false);

    const toggleModal = () => {
        setModalInvestorIsOpen(!modalInvestorIsOpen);
    }

    const openInvestorModal = (investidor) => {

        setSelectedInvestidor(investidor);
        toggleModal();
    }
    const closeInvestorModal = () => {

        setDetailsInvestor(false);
    }

    const userRef = 'c2quick';

    useEffect(() => {
        if (selectedItemIndex !== null) {
            const selectedEmissorData = investidores[selectedItemIndex];
            const investidorTransformado = transformarInvestidor(selectedEmissorData);
            setSelectedInvestidor(investidorTransformado);
        } else {
            setSelectedInvestidor(null);
        }
    }, [selectedItemIndex, investidores]);

    useEffect(() => {
        const fetchInvestidores = async () => {
            try {
                const response = await axios.get(`https://c2easybe-production.up.railway.app/investidor?userRef=${userRef}`);
                setInvestidores(response.data);
            } catch (error) {
                setInvestidores([]);
                console.error('Erro ao buscar investidores:', error);
            }
        };

        fetchInvestidores();
    }, []);

    const navigate = useNavigate()

    const handleSave = (investidor) => {
        localStorage.setItem('investidor', JSON.stringify(investidor));
        window.alert('Investidor Selecionado!');
        navigate('/Emissor');
    }
    const handleDeleteInfos = (index) => {
        setSelectedItemIndex(index);
        setShowConfirmation(true);
    }
    const toggleDetails = (index) => {
        console.log(index,'oqueeindex')
        setSelectedItemIndex(index);
        setDetailsInvestor(true);
    }
    const handleDelete = (investidorId) => {
        const url = 'https://c2easybe-production.up.railway.app';
        const headers = {
        }
        if (investidorId) {
            axios.delete(`${url}/investidor/${investidorId}`, headers)
                .then((response) => {
                    if (response.status === 204) {
                        setDeleteSuccess(true);
                        setTimeout(() => {
                            setDeleteSuccess(false);
                        }, 3000);
                        setSelectedItemIndex(null);
                        setShowConfirmation(false);

                        axios.get(`${url}/investidor?userRef=${userRef}`)
                            .then((response) => {
                                const updatedInvestidores = response.data;
                                setInvestidores(updatedInvestidores);
                            })
                            .catch((error) => {
                                console.error('Erro ao fazer a solicitação:', error);
                            });
                    } else {
                        setDeleteError(true);
                        setTimeout(() => {
                            setDeleteError(false);
                        }, 3000);
                    }
                })
                .catch((error) => {
                    console.error('Erro ao fazer a solicitação:', error);
                });
        }
    }

    function transformarInvestidor(investidor) {
        if (investidor.nome && investidor.nome2 && investidor.email) {
            const investidorTransformado = {
                ...investidor,
                representantes: [
                    { nome: investidor.nome, email: investidor.email }
                ]
            }

            // Se 'nome2' existir, adicione um segundo representante
            if (investidor.nome2) {
                investidorTransformado.representantes.push({
                    nome: investidor.nome2,
                    email: investidor.email
                })
            }

            return investidorTransformado;
        }

        return investidor; // Retorna o objeto original se não houver transformação
    }

    return (
        <Container>
            <Container>
                <h3>Selecione o investidor para emitir uma Nota Comercial</h3>
                {investidores.length > 0 ? (
                    <TableContainer component={Paper}>
                        <Table sx={{ backgroundColor: '#fff' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Razão Social</TableCell>
                                    <TableCell>CNPJ</TableCell>
                                    <TableCell>E-mail</TableCell>
                                    <TableCell>Telefone</TableCell>
                                    <TableCell>Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {investidores.map((investidor, index) => (
                                    <TableRow key={investidor._id}>
                                        <TableCell>{investidor.razaoSocial}</TableCell>
                                        <TableCell>{investidor.cnpj}</TableCell>
                                        <TableCell>{investidor.representantes[0].email}</TableCell>
                                        <TableCell>{investidor.telefone}</TableCell>
                                        <TableCell>
                                            <Tooltip title="Visualizar">
                                                <IconButton onClick={() => toggleDetails(index)} color="primary">
                                                    <VisibilityOutlined />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Editar">
                                                <IconButton onClick={() => openInvestorModal(investidor)} color="secondary">
                                                    <Edit />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Excluir">
                                                <IconButton onClick={() => handleDeleteInfos(index)} sx={{ color: red[500] }}>
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>
                                            <Dialog
                                                open={showConfirmation}
                                                onClose={() => setShowConfirmation(false)}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogTitle id="alert-dialog-title">
                                                    {"Confirmar Exclusão"}
                                                </DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText id="alert-dialog-description">
                                                        Tem certeza de que deseja excluir este investidor?
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={() => setShowConfirmation(false)} color="primary">
                                                        Cancelar
                                                    </Button>
                                                    <Button onClick={() => handleDelete(investidores[selectedItemIndex]._id)} color="primary" autoFocus>
                                                        Confirmar
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <div>Nenhum investidor encontrado</div>
                )}

            
            {detailsInvestor && (
                <InvestidorModal
                    selectedInvestidor={selectedInvestidor}
                    handleSave={handleSave}
                    onClose={closeInvestorModal}
                />
            )}
            {modalInvestorIsOpen && (
                    <UpdateInvestor
                        isOpen={modalInvestorIsOpen}
                        onClose={toggleModal}
                        investorData={selectedInvestidor}
                    />
                )}
            </Container>
        </Container>
    );
}

export default Investidores;

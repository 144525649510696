import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import DevedorSolidario from "../components/DevedorSolidario";
import { Box, Button, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";

Modal.setAppElement("#root"); // Define o elemento raiz da aplicação para o modal

export const formatCnpj = (cnpj) => {
    return cnpj?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
}

export const formatCpf = (cpf) => {
    return cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export const formatDocument = (text) => {
    if (text) {
        const cleanedText = text.replace(/\D/g, ''); // Remove non-numeric characters
        let formattedText = '';

        if (cleanedText.length <= 11) {
            // Format as CPF
            for (let i = 0; i < cleanedText.length; i++) {
                if (i === 3 || i === 6) {
                    formattedText += '.';
                } else if (i === 9) {
                    formattedText += '-';
                }
                formattedText += cleanedText[i];
            }
        } else {
            // Format as CNPJ
            for (let i = 0; i < cleanedText.length; i++) {
                if (i === 2 || i === 5) {
                    formattedText += '.';
                } else if (i === 8) {
                    formattedText += '/';
                } else if (i === 12) {
                    formattedText += '-';
                }
                formattedText += cleanedText[i];
            }
        }

        return formattedText;
    }
    return text; // Return the input text if it's empty
};

const EmissorModal = ({ isOpen, onClose, emissorData }) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const emissor = emissorData
    const storedData = JSON.parse(localStorage.getItem('notaComercial'));

    const [dadosBancarios, setDadosBancarios] = useState(storedData ? storedData.dadosBancarios : '');
    const [conta, setConta] = useState(emissor ? emissor.conta : '');
    const [agency, setAgency] = useState(emissor ? emissor.agency : '');
    const [banks, setBanks] = useState([]);
    const [selectedBank, setSelectedBank] = useState(emissor ? emissor.banco : '');
    const [bankNumber, setBankNumber] = useState(emissor ? emissor.bankNumber : '');
    const [devedor, setDevedor] = useState(true);
    const [fiel, setFiel] = useState(false);
    const [avalista, setAvalista] = useState(false);
    const [dRazao, setDRazao] = useState(emissor ? emissor.razaoSocial : '');
    const [dCnpj, setDCnpj] = useState(emissor ? emissor.cnpj : '');
    const [dCep, setDCep] = useState(emissor ? emissor.cep : '');
    const [dRua, setDRua] = useState(emissor ? emissor.rua : '');
    const [dCidade, setDCidade] = useState(emissor ? emissor.cidade : '');
    const [dEstado, setDEstado] = useState(emissor ? emissor.estado : '');
    const [dNumero, setDNumero] = useState(emissor ? emissor.numero : '');
    const [dBairro, setDBairro] = useState(emissor ? emissor.bairro : '');
    const [dComplemento, setDComplemento] = useState(emissor ? emissor.complemento : '');
    const [dEmail, setDEmail] = useState(emissor ? emissor.email : '');
    const [representante, setRepresentante] = useState(emissor ? emissor.representante : '');
    const [dCpf, setDCpf] = useState(emissor ? emissor.cpf : '');
    const [cargo, setCargo] = useState(emissor ? emissor.cargo : '');

    const [dAdress, setDAdress] = useState('');

    const [fName, setFName] = useState(emissor ? emissor.fielDepositario.nome : '');
    const [fNacionalidade, setFNacionalidade] = useState(emissor ? emissor.fielDepositario.nacionalidade : '');
    const [fOcupacao, setFOcupacao] = useState(emissor ? emissor.fielDepositario.ocupacao : '');
    const [fCep, setFCep] = useState(emissor ? emissor.fielDepositario.cep : '');
    const [fRua, setFRua] = useState(emissor ? emissor.fielDepositario.rua : '');
    const [fCidade, setFCidade] = useState(emissor ? emissor.fielDepositario.cidade : '');
    const [fEstado, setFEstado] = useState(emissor ? emissor.fielDepositario.estado : '');
    const [fNumero, setFNumero] = useState(emissor ? emissor.fielDepositario.numero : '');
    const [fComplemento, setFComplemento] = useState(emissor ? emissor.fielDepositario.complemento : '');
    const [fCpf, setFCpf] = useState(emissor ? emissor.fielDepositario.cpf : '');
    const [fRg, setFRg] = useState(emissor ? emissor.fielDepositario.rg : '');
    const [fEmail, setFEmail] = useState(emissor ? emissor.fielDepositario.email : '');
    const [avalistas, setAvalistas] = useState(emissor ? emissor.avalistas : [{ aNome: '', aCpf: '', aEmail: '', aCep: '', aRua: '', aCidade: '', aEstado: '', aNumero: '', aComplemento: '', aProfissao: '', aNacionalidade: '', aEstadoCivil: '', aDataNascimento: '' }]);
    const [meioPagamento, setMeioPagamento] = useState('[X] Transferência Eletrônica Disponível (TED)');
    const [erroValidacao, setErroValidacao] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [solidario, setSolidario] = useState(false);
    const [devedorInfo, setDevedorInfo] = useState({});

    const [editedData, setEditedData] = useState({ ...emissorData });
    const toggleSolidario = () => {
        setSolidario(!solidario);
    }
    useEffect(() => {
        setEditedData({ ...emissorData });
    }, [emissorData]);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const updateFormData = (newData) => {
        setDevedorInfo(newData);
    };
    useEffect(() => {
        fetch('https://brasilapi.com.br/api/banks/v1')
            .then(response => response.json())
            .then(data => setBanks(data))
            .catch(error => console.error('Ocorreu um erro ao buscar os bancos:', error));
        setDadosBancarios(`Banco: ${selectedBank}-${bankNumber}/Agencia: ${agency}/Conta: ${conta}`);
    }, [dadosBancarios]);
    const handleBankChange = (event) => {
        const selectedBankName = event.target.value;
        setSelectedBank(selectedBankName);
        const selectedBankData = banks.find(bank => bank.name === selectedBankName);

        if (selectedBankData) {
            setBankNumber(selectedBankData.code);
        }
        else if (selectedBankName === 'BNK Digital') {
            setBankNumber(547);
        } else {
            setBankNumber('');
        }
    };
    const keywordsToPrioritize = ['caixa eco', 'itaú uni', 'bco do brasil', 'bradesco s', 'santander', 'original', 'banco inter', 'bdigital'];

    const sortedBanks = [...banks].sort((a, b) => {
        const bankNameA = a.name ? a.name.toLowerCase() : '';
        const bankNameB = b.name ? b.name.toLowerCase() : '';

        const priorityA = getPriority(bankNameA);
        const priorityB = getPriority(bankNameB);

        if (priorityA !== priorityB) {
            return priorityA - priorityB; // Coloque o banco com prioridade maior primeiro
        } else {
            return bankNameA.localeCompare(bankNameB); // Ordem alfabética padrão
        }
    });
    const addAvalista = () => {
        setAvalistas([...avalistas, { aNome: '', aCpf: '', aEmail: '' }]);
    };
    function getPriority(name) {
        for (let i = 0; i < keywordsToPrioritize.length; i++) {
            if (name.includes(keywordsToPrioritize[i])) {
                return i; // Retorna a posição da primeira correspondência encontrada
            }
        }
        return keywordsToPrioritize.length; // Se não houver correspondências, use a prioridade máxima
    }

    const sendInfos = () => {
        const dados = {
            userRef: 'c2quick',
            razaoSocial: dRazao,
            cnpj: dCnpj,
            email: dEmail,
            cpf: dCpf,
            representante,
            cargo,
            banco: selectedBank,
            bankNumber,
            conta,
            agency,
            cep: dCep,
            rua: dRua,
            numero: dNumero,
            bairro: dBairro,
            cidade: dCidade,
            estado: dEstado,
            complemento: dComplemento,
            fielDepositario: {
                nome: fName,
                nacionalidade: fNacionalidade,
                ocupacao: fOcupacao,
                cep: fCep,
                rua: fRua,
                numero: fNumero,
                complemento: fComplemento,
                cidade: fCidade,
                estado: fEstado,
                cpf: fCpf,
                rg: fRg,
                email: fEmail

            },
            devedorSolidario: devedorInfo,
            avalistas,
            meioPagamento,
        }

        // Envia os dados atualizados para a API
        console.log(dados, 'editedData');

        axios.put(`https://c2easybe-production.up.railway.app/emissor/${emissorData._id}`, dados)
            .then((response) => {
                console.log(response.data);
                localStorage.setItem('emissor', JSON.stringify(dados));
                window.alert('Dados atualizados com sucesso!');
                onClose();
            })
            .catch((error) => {
                // Lógica para lidar com erros na requisição
                console.error("Erro ao atualizar dados do investidor:", error);
            });
    };
    const handleAvalistaChange = (e, index, field) => {
        const newAvalistas = [...avalistas];
        newAvalistas[index][field] = e.target.value;
        setAvalistas(newAvalistas);
    };
    const toggleAvalistaVisibility = () => {
        setAvalista(!avalista);
    };

    const removeAvalista = (indexToRemove) => {
        const updatedAvalistas = [...avalistas];
        updatedAvalistas.splice(indexToRemove, 1); // Remove o avalista pelo índice
        setAvalistas(updatedAvalistas);
    };
    const toggleDevedorVisibility = () => {
        setDevedor(!devedor);
    };

    const handleSearch2 = async () => {
        try {
            setIsLoading2(true);
            const response = await axios.get(`https://viacep.com.br/ws/${dCep}/json/`);
            setDAdress(response.data);
            setDBairro(response.data.bairro);
            setDCidade(response.data.localidade);
            setDEstado(response.data.uf);
            setDRua(response.data.logradouro);
        } catch (error) {
            console.error('Error fetching address:', error);
        } finally {
            setIsLoading2(false);
        }
    };
    const toggleFielVisibility = () => {
        setFiel(!fiel);
    };
    const handleSearch = async (cep) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
            const data = response.data;

            setFRua(data.logradouro || "");
            setFNumero("");
            setFComplemento("");
            setFCidade(data.localidade || "");
            setFEstado(data.uf || "");
        } catch (error) {
            console.error('Erro ao buscar endereço:', error);
        } finally {
            setIsLoading(false);
        }
    };
    const handleCepChange = (e) => {
        const newCep = e.target.value.replace(/\D/g, "");
        setFCep(newCep);

        if (newCep.length === 8) {
            handleSearch(newCep);
        }
    };

    const handleSearchAva = async (cep, index) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
            const data = response.data;

            const newAvalistas = [...avalistas];
            const currentAvalista = newAvalistas[index];

            currentAvalista.aRua = data.logradouro || "";
            currentAvalista.aNumero = "";
            currentAvalista.aBairro = "";
            currentAvalista.aCidade = data.localidade || "";
            currentAvalista.aEstado = data.uf || "";

            setAvalistas(newAvalistas);
        } catch (error) {
            console.error('Erro ao buscar endereço:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCepChangeAva = (e, index, field) => {
        const newCep = e.target.value.replace(/\D/g, "");
        const newAvalistas = [...avalistas];
        newAvalistas[index][field] = e.target.value;
        setAvalistas(newAvalistas);

        if (newCep.length === 8) {
            handleSearchAva(newCep, index);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Investor Modal"
        >

            <Box className="modalBody">
                <Button onClick={toggleDevedorVisibility} className="newButtonEm">
                    {devedor ? "Ocultar Cadastro de Emissor" : "Exibir Cadastro de Emissor ↓"}
                </Button>
                {devedor ?

                    <Grid container spacing={2} mt={2}>

                        <Typography
                            variant="h6"
                            component="h2"
                            marginBottom={0.5}
                            width={"100%"}
                        >
                            EMISSOR</Typography>

                        <Grid item xs={6}>
                            <TextField
                                label="Razão Social do Emissor:"
                                fullWidth
                                value={dRazao}
                                onChange={(e) => { setDRazao(e.target.value) }}
                                maxLength={120} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="CNPJ:"
                                fullWidth
                                value={formatCnpj(dCnpj)}
                                onChange={(e) => { setDCnpj(e.target.value) }}
                                maxLength={18} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                mask="99999-999" ˇ
                                maskChar=""
                                fullWidth
                                label="CEP"
                                className={`inputsDoc`}
                                value={dCep}
                                onChange={(e) => { setDCep(e.target.value) }}
                                onBlur={handleSearch2}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Número:"
                                value={dNumero}
                                onChange={(e) => { setDNumero(e.target.value) }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Complemento:"
                                value={dComplemento}
                                onChange={(e) => { setDComplemento(e.target.value) }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Rua:"
                                fullWidth
                                value={dRua}
                                onChange={(e) => { setDRua(e.target.value) }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                label="Bairro:"
                                fullWidth
                                value={dBairro}
                                onChange={(e) => { setDBairro(e.target.value) }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                label="Cidade:"
                                fullWidth
                                value={dCidade}
                                onChange={(e) => { setDCidade(e.target.value) }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                label="Estado:"
                                fullWidth
                                value={dEstado}
                                onChange={(e) => { setDEstado(e.target.value) }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                label="E-mail:"
                                fullWidth
                                value={dEmail}
                                onChange={(e) => { setDEmail(e.target.value) }}
                                maxLength={40}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                label="Representante Legal:"
                                fullWidth
                                value={representante}
                                onChange={(e) => { setRepresentante(e.target.value) }}
                                maxLength={40}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                label="CPF:"
                                fullWidth
                                value={formatCpf(dCpf)}
                                onChange={(e) => { setDCpf(e.target.value) }}
                                maxLength={14}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                label="Cargo:"
                                fullWidth
                                value={cargo}
                                onChange={(e) => { setCargo(e.target.value) }}
                                maxLength={40}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel>Banco</InputLabel>
                                <Select
                                    value={selectedBank}
                                    onChange={handleBankChange}
                                    className="selectDoc"
                                    inputProps={{
                                        name: 'dadosBancarios',
                                        id: 'dadosBancarios',
                                    }}
                                >
                                    <MenuItem value="" className="optionInputsDoc">Selecione um banco</MenuItem>
                                    <MenuItem value="BNK Digital" className="optionInputsDoc">BNK Digital</MenuItem>
                                    {sortedBanks.map((bank, index) => (
                                        <MenuItem key={index} value={bank.name} className="optionInputsDoc">
                                            {bank.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Número do Banco"
                                fullWidth
                                value={bankNumber}
                                onChange={(e) => { setBankNumber(e.target.value) }}
                                maxLength={20}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                label="Agência"
                                placeholder="12345"
                                fullWidth
                                value={agency}
                                onChange={(e) => { setAgency(e.target.value) }}
                                maxLength={8}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                label="Conta"
                                placeholder="12345"
                                fullWidth
                                value={conta}
                                onChange={(e) => { setConta(e.target.value) }}
                                maxLength={10}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="meioPagamento">Meio de Pagamento</InputLabel>
                                <Select
                                    value={meioPagamento}
                                    onChange={(e) => { setMeioPagamento(e.target.value) }}
                                    className="selectDoc"
                                    inputProps={{
                                        name: 'meioPagamento',
                                        id: 'meioPagamento',
                                    }}
                                >
                                    <MenuItem value="[X] Transferência Eletrônica Disponível (TED)">Transferência Eletrônica Disponível (TED)</MenuItem>
                                    <MenuItem value="[X] Dação em Pagamento">Dação em Pagamento</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                    </Grid>
                    : <br />}
                <Button onClick={toggleAvalistaVisibility}>
                    {avalista ? "Ocultar Cadastro de Avalista" : "Exibir Cadastro de Avalista ↓"}
                </Button >
                {
                    avalista ?
                        <div className='formDoc1'>
                            {avalistas.map((avalista, index) => (
                                <Grid key={index}>
                                    <Typography variant="h5" component="h1">Avalista {index + 1}</Typography>

                                    <Grid container spacing={2} mt={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Nome"
                                                fullWidth
                                                value={avalista.aNome}
                                                onChange={(e) => handleAvalistaChange(e, index, 'aNome')}
                                                maxLength={40}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                label="CPF/CNPJ"
                                                fullWidth
                                                value={formatDocument(avalista.aCpf)}
                                                onChange={(e) => handleAvalistaChange(e, index, 'aCpf')}
                                                maxLength={19}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                label="Email"
                                                fullWidth
                                                value={avalista.aEmail}
                                                onChange={(e) => handleAvalistaChange(e, index, 'aEmail')}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                label="País de Nascimento"
                                                fullWidth
                                                value={avalista.aNascimento}
                                                onChange={(e) => handleAvalistaChange(e, index, 'aNascimento')}
                                                maxLength={40}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <InputLabel htmlFor={`estadoCivil${index}`}>Estado Civil</InputLabel>
                                                <Select
                                                    value={avalista.aEstadoCivil}
                                                    onChange={(e) => handleAvalistaChange(e, index, 'aEstadoCivil')}
                                                    className="selectDoc"
                                                    inputProps={{
                                                        name: `estadoCivil${index}`,
                                                        id: `estadoCivil${index}`,
                                                    }}
                                                >
                                                    <MenuItem value="solteiro">Solteiro</MenuItem>
                                                    <MenuItem value="casado">Casado</MenuItem>
                                                    <MenuItem value="divorciado">Divorciado</MenuItem>
                                                    <MenuItem value="viuvo">Viúvo</MenuItem>
                                                    {/* Adicione mais opções conforme necessário */}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                label="Profissão"
                                                fullWidth
                                                value={avalista.aProfissao}
                                                onChange={(e) => handleAvalistaChange(e, index, 'aProfissao')}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                label="Data de Nascimento"
                                                fullWidth
                                                type="date"
                                                value={avalista.aDataNascimento}
                                                onChange={(e) => handleAvalistaChange(e, index, 'aDataNascimento')}
                                                maxLength={40}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                label="CEP"
                                                fullWidth
                                                value={avalista.aCep}
                                                onChange={(e) => handleCepChangeAva(e, index, 'aCep')}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                label="Rua"
                                                fullWidth
                                                value={avalista.aRua}
                                                onChange={(e) => handleAvalistaChange(e, index, 'aRua')}
                                                maxLength={14}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                label="Número"
                                                fullWidth
                                                value={avalista.aNumero}
                                                onChange={(e) => handleAvalistaChange(e, index, 'aNumero')}
                                                maxLength={14}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                label="Bairro"
                                                fullWidth
                                                value={avalista.aBairro}
                                                onChange={(e) => handleAvalistaChange(e, index, 'aBairro')}
                                                maxLength={14}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                label="Cidade"
                                                fullWidth
                                                value={avalista.aCidade}
                                                onChange={(e) => handleAvalistaChange(e, index, 'aCidade')}
                                                maxLength={14}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                label="Estado"
                                                fullWidth
                                                value={avalista.aEstado}
                                                onChange={(e) => handleAvalistaChange(e, index, 'aEstado')}
                                                maxLength={14}
                                            />
                                        </Grid>

                                        <div className="addButton2" onClick={() => removeAvalista(0)}>
                                            Remover Avalista
                                        </div>
                                    </Grid>
                                </Grid>
                            ))}
                            <div className="addButton" onClick={addAvalista}>
                                + Adicionar Avalista
                            </div>
                        </div> : <br />}
                <Button onClick={toggleFielVisibility}>
                    {fiel ? "Ocultar Cadastro de Fiel Depositário" : "Exibir Cadastro de Fiel Depositário ↓"}
                </Button>
                {fiel ?
                    <form className="formDoc1">
                        <div className="divTitleDoc">
                            <h1 className="h1Doc">FIEL DEPOSITÁRIO</h1>
                        </div>

                        <Grid container spacing={2} mt={2}>
                            <Grid item xs={6}>
                                <TextField
                                    label="Nome"
                                    fullWidth
                                    value={fName}
                                    onChange={(e) => { setFName(e.target.value) }}
                                    maxLength={40}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="Nacionalidade"
                                    fullWidth
                                    value={fNacionalidade}
                                    onChange={(e) => { setFNacionalidade(e.target.value) }}
                                    maxLength={40}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="Ocupação"
                                    fullWidth
                                    value={fOcupacao}
                                    onChange={(e) => { setFOcupacao(e.target.value) }}
                                    maxLength={40}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="CEP"
                                    fullWidth
                                    value={fCep}
                                    onChange={handleCepChange}
                                />
                            </Grid>

                            {isLoading ? (
                                <p className="loading">Carregando...</p>
                            ) : (
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <TextField
                                                label="Rua"
                                                fullWidth
                                                value={fRua}
                                                onChange={(e) => setFRua(e.target.value)}
                                                maxLength={40}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                label="Número"
                                                fullWidth
                                                value={fNumero}
                                                onChange={(e) => setFNumero(e.target.value)}
                                                maxLength={40}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                label="Complemento"
                                                fullWidth
                                                value={fComplemento}
                                                onChange={(e) => setFComplemento(e.target.value)}
                                                maxLength={40}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}

                            <Grid item xs={6}>
                                <TextField
                                    label="Cidade"
                                    fullWidth
                                    value={fCidade}
                                    onChange={(e) => setFCidade(e.target.value)}
                                    maxLength={40}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="Estado"
                                    fullWidth
                                    value={fEstado}
                                    onChange={(e) => setFEstado(e.target.value)}
                                    maxLength={40}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="CPF"
                                    fullWidth
                                    value={formatCpf(fCpf)}
                                    onChange={(e) => { setFCpf(e.target.value) }}
                                    maxLength={14}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="RG"
                                    fullWidth
                                    value={fRg}
                                    onChange={(e) => { setFRg(e.target.value) }}
                                    maxLength={40}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="e-mail"
                                    fullWidth
                                    value={fEmail}
                                    onChange={(e) => { setFEmail(e.target.value) }}
                                    maxLength={40}
                                />
                            </Grid>
                        </Grid>
                    </form>
                    : <br />}
                <Button onClick={toggleSolidario} className="newButtonEm">
                    {solidario ? "Ocultar Cadastro de Devedor Solidário" : "Exibir Cadastro de Devedor Solidário  ↓"}
                </Button>
                {solidario ?
                <form className="formDoc1">
                    <DevedorSolidario updateFormData={updateFormData} style={{width: '100%'}} />
                </form>
                    :
                    <br />}
                <Grid sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>

                    <Button onClick={sendInfos}>Atualizar Emissor</Button>
                    <Button onClick={onClose}>Cancelar</Button>
                </Grid >
                {erroValidacao && (
                    <div className="mensagemErro">
                        Erro de validação: {erroValidacao}
                    </div>
                )}
            </Box>

        </Modal >
    );
};

export default EmissorModal;
import React from 'react';
import { Box, List, ListItem, ListItemText, Divider } from "@mui/material";
import { Link } from "react-router-dom"; // Certifique-se de ter react-router-dom instalado

const SideHeader = ({ selected }) => {
    return (
        <Box sx={{ width: '25%', height: '90vh', backgroundColor: 'grey', borderRadius: '10px 10px 10px 10px', m: 1, p: 1 }}>
            <List sx={{
                padding: 1,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
            }}>
                <img
                    src={'https://i.ibb.co/VYbWFpr/logo.png'}
                    alt="Logo"
                    style={{
                        width: '70%',
                        height: '100%',
                        padding: '10px',
                    }} />
                <ListItem button selected={selected === 'calculo'} component={Link} to="/">
                    <ListItemText primary="Simular Cálculo" sx={{ color: 'white', fontWeight: 'bold' }} />
                </ListItem>
                <ListItem button selected={selected === 'resultado'} component={Link} to="/resultado">
                    <ListItemText primary="Resultado da Simulação" sx={{ color: 'white', fontWeight: 'bold' }} />
                </ListItem>
                <ListItem button selected={selected === 'investidor'} component={Link} to="/investidor">
                    <ListItemText primary="Cadastro de Investidor" sx={{ color: 'white', fontWeight: 'bold' }} />
                </ListItem>
                <ListItem button selected={selected === 'emissor'} component={Link} to="/emissor">
                    <ListItemText primary="Cadastro de Emissor" sx={{ color: 'white', fontWeight: 'bold' }} />
                </ListItem>
                <Divider />
                <ListItem button selected={selected === 'minuta'} component={Link} to="/minuta">
                    <ListItemText primary="Minuta" sx={{ color: 'white', fontWeight: 'bold' }} />
                </ListItem>
            </List>
        </Box>
    );
};

export default SideHeader;

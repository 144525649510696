const INITIAL_STATE = {
    emissor: [],
    investidor: [],
    loading: false,
    error: null,
};

function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'CADASTRAR_EMISSOR_REQUEST':
            return { ...state, loading: true, error: null };
        case 'CADASTRAR_EMISSOR_SUCCESS':
            return {
                ...state,
                emissor: action.payload,
                loading: false
            };
        case 'CADASTRAR_EMISSOR_ERROR':
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case 'INVESTIDOR':
            return {
                ...state,
                investidor: action.payload
            };
        default:
            return state;
    }
}

export default reducer
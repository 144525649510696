import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { formatBankAccount, formatarTelefone } from "../components/Investidor";
import axios from "axios";
import {
    Box, Typography, TextField, Button, Select, MenuItem,
    FormControl, InputLabel, Grid, IconButton, Container
} from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";


Modal.setAppElement("#root"); // Define o elemento raiz da aplicação para o modal

export const formatCnpj = (text) => {
    if (text) {
        const cnpj = text?.replace(/[^0-9a-zA-Z]/g, '').toUpperCase();
        return cnpj;
    }
}

const style = {
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,

};


const InvestorModal = ({ isOpen, onClose, investorData }) => {
    console.log(investorData);
    const [razaoSocial, setRazaoSocial] = useState(investorData.razaoSocial);
    const [cnpj, setCnpj] = useState(investorData.cnpj);
    const [cep, setCep] = useState(investorData.cep);
    const [credorRua, setCredorRua] = useState(investorData.rua);
    const [credorNumero, setCredorNumero] = useState(investorData.numero);
    const [credorComplemento, setCredorComplemento] = useState(investorData.complemento);
    const [loading, setLoading] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [credorBairro, setCredorBairro] = useState(investorData.bairro);
    const [credorCidade, setCredorCidade] = useState(investorData.cidade);
    const [credorEstado, setCredorEstado] = useState(investorData.estado);
    const [email, setEmail] = useState(investorData.email);
    const [telefone, setTelefone] = useState(investorData.telefone);
    const [selectedBank, setSelectedBank] = useState(investorData.selectedBank);
    const [bankNumber, setBankNumber] = useState(investorData.bankNumber);
    const [conta, setConta] = useState(investorData.conta);
    const [agency, setAgency] = useState(investorData.agencia);
    const [tipoDeInstituicao, setTipoDeInstituicao] = useState(investorData.tipoDeInstituicao);
    const [name, setName] = useState(investorData.nome);
    const [name2, setName2] = useState(investorData.nome2);
    const [dadosBancarios, setDadosBancarios] = useState('');
    const [banks, setBanks] = useState([]);
    const [representantes, setRepresentantes] = useState([{ nome: '', email: '' }]);

    const [editedData, setEditedData] = useState({ ...investorData });

    const user = {
        data: {
            razaoSocial: "Jozinho"
        }
    }

    useEffect(() => {
        setEditedData({ ...investorData });
    }, [investorData]);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    useEffect(() => {
        fetch('https://brasilapi.com.br/api/banks/v1')
            .then(response => response.json())
            .then(data => setBanks(data))
            .catch(error => console.error('Ocorreu um erro ao buscar os bancos:', error));
        setDadosBancarios(`Banco: ${selectedBank}-${bankNumber}/Agencia: ${agency}/Conta: ${conta}`);
    }, [dadosBancarios]);

    const handleSearch = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }

    const addRepresentante = () => {
        setRepresentantes([...representantes, { nome: '', email: '' }]); // Adiciona um novo representante vazio
    };

    const removeRepresentante = (index) => {
        const updatedRepresentantes = [...representantes];
        updatedRepresentantes.splice(index, 1);
        setRepresentantes(updatedRepresentantes);
    };
    const handleBankChange = (event) => {
        const selectedBankName = event.target.value;
        setSelectedBank(selectedBankName);
        const selectedBankData = banks.find(bank => bank.name === selectedBankName);

        if (selectedBankData) {
            setBankNumber(selectedBankData.code);
        }
        else if (selectedBankName === 'BNK Digital') {
            setBankNumber(547);
        } else {
            setBankNumber('');
        }
    };
    const keywordsToPrioritize = ['caixa eco', 'itaú uni', 'bco do brasil', 'bradesco s', 'santander', 'original', 'banco inter', 'bdigital'];

    const sortedBanks = [...banks].sort((a, b) => {
        const bankNameA = a.name ? a.name.toLowerCase() : '';
        const bankNameB = b.name ? b.name.toLowerCase() : '';

        const priorityA = getPriority(bankNameA);
        const priorityB = getPriority(bankNameB);

        if (priorityA !== priorityB) {
            return priorityA - priorityB; // Coloque o banco com prioridade maior primeiro
        } else {
            return bankNameA.localeCompare(bankNameB); // Ordem alfabética padrão
        }
    });
    const handleRepresentanteChange = (index, field, value) => {
        const updatedRepresentantes = [...representantes];
        updatedRepresentantes[index][field] = value;
        setRepresentantes(updatedRepresentantes);
    };
    function getPriority(name) {
        for (let i = 0; i < keywordsToPrioritize.length; i++) {
            if (name.includes(keywordsToPrioritize[i])) {
                return i; // Retorna a posição da primeira correspondência encontrada
            }
        }
        return keywordsToPrioritize.length; // Se não houver correspondências, use a prioridade máxima
    }

    const handleSave = () => {
        const editedData = {
            razaoSocial,
            cnpj,
            cep,
            rua: credorRua,
            numero: credorNumero,
            complemento: credorComplemento,
            bairro: credorBairro,
            cidade: credorCidade,
            estado: credorEstado,
            representantes,
            telefone,
            selectedBank,
            bankNumber,
            conta,
            agencia: agency,
            tipoDeInstituicao,

        };

        // Envia os dados atualizados para a API

        axios.put(`https://c2easybe-production.up.railway.app/investidor/${investorData._id}`, editedData)
            .then((response) => {
                window.alert('Dados atualizados com sucesso!');
                onClose();
            })
            .catch((error) => {
                // Lógica para lidar com erros na requisição
                console.error("Erro ao atualizar dados do investidor:", error);
            });
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Investor Modal"
        >
            <Box sx={style}>
                <Typography variant="h6" component="h2" marginBottom={0.5}>
                    Detalhes do Investidor
                </Typography>

                <Grid container spacing={2} mt={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Razão Social"
                            fullWidth
                            value={razaoSocial}
                            onChange={(e) => setRazaoSocial(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="CNPJ"
                            fullWidth
                            value={formatCnpj(cnpj)}
                            onChange={(e) => setCnpj(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="CEP"
                            fullWidth
                            value={cep}
                            onChange={(e) => setCep(e.target.value)}
                            onBlur={handleSearch}
                        />
                        {loading && <p width="10%">Buscando...</p>}
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            label="Rua"
                            fullWidth
                            value={credorRua}
                            onChange={(e) => setCredorRua(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            label="Número"
                            fullWidth
                            value={credorNumero}
                            onChange={(e) => setCredorNumero(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            label="Complemento"
                            fullWidth
                            value={credorComplemento}
                            onChange={(e) => setCredorComplemento(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            label="Bairro"
                            fullWidth
                            value={credorBairro}
                            onChange={(e) => setCredorBairro(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            label="Cidade"
                            fullWidth
                            value={credorCidade}
                            onChange={(e) => setCredorCidade(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Estado"
                            fullWidth
                            value={credorEstado}
                            onChange={(e) => setCredorEstado(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Representantes</Typography>
                        {representantes.map((representante, index) => (
                            <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
                                <Grid item xs={6}>
                                    <TextField
                                        label={`Representante ${index + 1}`}
                                        fullWidth
                                        value={representante.nome}
                                        onChange={(e) => handleRepresentanteChange(index, 'nome', e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="E-mail"
                                        fullWidth
                                        value={representante.email}
                                        onChange={(e) => handleRepresentanteChange(index, 'email', e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <IconButton color="primary" aria-label="add" onClick={() => addRepresentante()}>
                                        <AddCircleOutline />
                                    </IconButton>
                                    {index > 0 && (
                                        <IconButton aria-label="delete" onClick={() => removeRepresentante(index)}>
                                            <RemoveCircleOutline />
                                        </IconButton>
                                    )}
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            label="Telefone"
                            fullWidth
                            value={formatarTelefone(telefone)}
                            onChange={(e) => setTelefone(e.target.value)}
                            sx={{ mb: 2 }}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Dados Bancários</InputLabel>
                            <Select
                                value={selectedBank}
                                onChange={handleBankChange}
                                label="Dados Bancários"
                            >
                                <MenuItem value="">Selecione um banco</MenuItem>
                                <MenuItem value="BNK Digital">BNK Digital</MenuItem>
                                {sortedBanks.map((bank, index) => (
                                    <MenuItem key={index} value={bank.name}>
                                        {bank.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Número do Banco"
                            fullWidth
                            value={bankNumber}
                            onChange={(e) => setBankNumber(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Agência"
                            placeholder="12345"
                            fullWidth
                            value={agency}
                            onChange={(e) => setAgency(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Conta"
                            placeholder="123 456-X"
                            fullWidth
                            value={formatBankAccount(conta)}
                            onChange={(e) => setConta(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel>Tipo de Instituição</InputLabel>
                            <Select
                                value={tipoDeInstituicao}
                                onChange={(e) => setTipoDeInstituicao(e.target.value)}
                                label="Tipo de Instituição"
                            >
                                <MenuItem value="FIDC">FIDC</MenuItem>
                                <MenuItem value="Factoring">Factoring</MenuItem>
                                <MenuItem value="Securitizadora">Securitizadora</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={10} sx={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Button variant="contained" color="primary" onClick={handleSave}>
                            Salvar
                        </Button>
                        <Button variant="outlined" onClick={onClose}>
                            Cancelar
                        </Button>
                    </Grid>
                </Grid>

            </Box>
        </Modal>
    );
};

export default InvestorModal;

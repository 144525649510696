import axios from 'axios';
import * as actions from './actions';
import {
    all,
    takeLatest,
    call,
    put,
    delay,
    take,
    takeEvery,
    takeLeading,
} from 'redux-saga/effects';
import { api } from './services/api';

export function* cadastrarEmissor(action) {
    console.log("action:", action)
    const { emissor } = action.payload;

    try {

        const response = yield call(api.post, '/v1/emissores/solicitacoes-de-cadastro', emissor);

        if (response.status === 201) {
            console.log("response:", response)
            yield put(actions.cadastrarEmissorSuccess());
        }

    } catch (error) {
        console.log("error:", error)
        yield put(actions.cadastrarEmissorError());
    }
}

export default function* rootSaga() {
    return yield all([
        takeLatest('CADASTRAR_EMISSOR_REQUEST', cadastrarEmissor),
    ]);
}
import React from "react";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    Typography,
    Grid,
    Box
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { blue, grey } from "@mui/material/colors";



const useStyles = makeStyles({
    closeButton: {
        position: 'absolute',
        right: 8,
        top: 8,
        color: grey[500],
    },
    detailText: {
        marginBottom: 10,
    },
    modalTitle: {
        fontWeight: 'bold',
        color: blue[600],
        textAlign: 'center',
        padding: '16px 24px',
        position: 'relative',
    },
    modalContent: {
        padding: 24,
    },
    button: {
        marginTop: 20,
        backgroundColor: blue[600],
        color: '#fff',
        '&:hover': {
            backgroundColor: blue[700],
        }
    }
});

function InvestidorModal({ selectedInvestidor, handleSave, onClose }) {
    console.log(selectedInvestidor, 'ertyuiop[')
    const classes = useStyles();

    return (
        <Dialog open={!!selectedInvestidor} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle className={classes.modalTitle}>
            Detalhes do Investidor Selecionado
            <Button onClick={onClose} className={classes.closeButton}>
                <CloseIcon />
            </Button>
        </DialogTitle>
        <DialogContent dividers className={classes.modalContent}>
                <Grid container spacing={2}>
                    <Grid item xs={12} className={classes.detailText}>
                        <Typography variant="subtitle1">Razão Social:</Typography>
                        <Typography variant="body1">{selectedInvestidor?.razaoSocial}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.detailText}>
                        <Typography variant="subtitle1">CNPJ:</Typography>
                        <Typography variant="body1">{selectedInvestidor?.cnpj}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.detailText}>
                        <Typography variant="subtitle1">Representante:</Typography>
                        <Typography variant="body1">{selectedInvestidor?.representantes[0].nome}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.detailText}>
                        <Typography variant="subtitle1">Endereço:</Typography>
                        <Box>
                            <Typography variant="body1" component="span">CEP: {selectedInvestidor?.cep}<br /></Typography>
                            <Typography variant="body1" component="span">Rua: {selectedInvestidor?.rua}<br /></Typography>
                            <Typography variant="body1" component="span">Bairro: {selectedInvestidor?.bairro}<br /></Typography>
                            <Typography variant="body1" component="span">Cidade: {selectedInvestidor?.cidade}<br /></Typography>
                            <Typography variant="body1" component="span">UF: {selectedInvestidor?.estado}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <Box textAlign="center">
                <Button 
                    variant="contained" 
                    className={classes.button} 
                    onClick={() => handleSave(selectedInvestidor)}
                >
                    Selecionar Investidor para Nota Comercial
                </Button>
            </Box>
        </Dialog>
    );
}

export default InvestidorModal;

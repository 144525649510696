import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainCalc from './components/MainCalc';
import Resultado from './components/Resultado';
import Investidor from './components/Investidor';
import Emissor from './components/Emissor';
import Perfil from './components/Perfil';
import store from './redux/store';
import { Provider } from 'react-redux';
import Minuta from './components/Minuta';
import Login from './components/Login';

function App() {
  document.title = 'Nota Comercial';

  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<MainCalc />} />
          <Route path="/resultado" element={<Resultado />} />
          <Route path="/investidor" element={<Investidor />} />
          <Route path="/emissor" element={<Emissor />} />
          <Route path="/perfil" element={<Perfil />} />
          <Route path="/minuta" element={<Minuta />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;

import React, { useState } from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import { Document, Page, Text, View, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import SideHeader from "./Header";
import { pdfStyle } from "../utils/pdfStyles";
import { chunkArray } from "../utils/chunkArray";
import { formatCnpj } from "../Modals/UpdateInvestor";
import { formatCpf } from "../Modals/UpdateEmissor";

const Minuta = () => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const calcData = JSON.parse(localStorage.getItem('calcData'));
    const now = new Date();
    const formData = JSON.parse(localStorage.getItem('formData'));

    const styles = pdfStyle;
    const chunkedCalcData = chunkArray(calcData, 9);
    let isFirstPage = true;
    const emissor = JSON.parse(localStorage.getItem('emissor'));
    const investidor = JSON.parse(localStorage.getItem('investidor'));

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#fff",
            }}
        >
            <SideHeader selected="minuta" />
            <Container
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column", // Alterei de 'row' para 'column' para empilhar verticalmente
                    height: "72%",
                    backgroundColor: "#fff",
                    borderRadius: "15px",
                    paddingBottom: "20px",
                }}
            >
                <Typography variant="h5">Minuta</Typography>
                <PDFViewer width="1000" height="600">
                    <Document onLoadSuccess={onDocumentLoadSuccess}>
                        <Page style={styles.page}>
                            <Text style={[styles.textBold, { fontSize: 10 }]}>
                                TERMO CONSTITUTIVO DE NOTA COMERCIAL DA [--]ª ([--]) EMISSÃO DE NOTAS COMERCIAIS, EM SÉRIE ÚNICA, PARA COLOCAÇÃO PRIVADA, DO(A) {emissor.razaoSocial.toUpperCase()}.
                            </Text>
                            <View style={styles.section}>
                                <Text style={styles.h1}>I. EMISSORA</Text>
                                <View style={styles.tableContainer}>
                                    <View style={styles.row}>
                                        <Text style={styles.cellRight}><Text style={styles.headerCell}>Razão Social</Text> {'\n'}{emissor.razaoSocial}</Text>
                                        <Text style={styles.cell}><Text style={styles.headerCell}>CNPJ/ME</Text>{'\n'}{formatCnpj(emissor.cnpj)}</Text>
                                    </View>
                                </View>
                                <Text style={styles.text3}>
                                    A Emissão e a celebração deste Termo Constitutivo de Nota Comercial da [--]ª ([--]) Emissão de Notas Comerciais, em Série Única,
                                    para Colocação Privada, da [--](“Termo Constitutivo”) são realizadas com base nas deliberações dos administradores da
                                    Emissora, conforme previsto no Parágrafo único, artigo 46 da Lei nº14.195, de 26 de agosto de 2021 (“Lei 14.195”) e no
                                    [contrato] OU [estatuto] social da Emissora.
                                </Text>
                                <Text style={styles.h1}>II. RESUMO DA EMISSÃO</Text>
                                <View style={styles.tableContainer}>
                                    <View style={styles.row}>
                                        <Text style={styles.cell2}><Text style={styles.textBold}>Número da Emissão</Text>{'\n'}
                                            [--]ª ([--])</Text>
                                        <Text style={styles.cell2}><Text style={styles.textBold}>Série</Text>{'\n'}Única</Text>
                                        <Text style={styles.cell2}><Text style={styles.textBold}>Local de Emissão{'\n'}</Text>
                                            {emissor.cidade}</Text>
                                        <Text style={[styles.cell2, { borderRightWidth: 0 }]}><Text style={styles.textBold}>Data de Emissão</Text>{'\n'}
                                            {now.getDate()}/{now.getMonth() + 1}/{now.getFullYear()}</Text>
                                    </View>
                                    <View style={styles.row}>
                                        <Text style={[styles.cell2]}><Text style={styles.textBold}>Data de Vencimento</Text>{'\n'}
                                            {formData.vencimentoDia}/{formData.vencimentoMes}/{formData.vencimentoAno}</Text>

                                        <Text style={styles.cell2}><Text style={styles.textBold}>Valor Total da Emissão</Text>{'\n'}
                                            {formData.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Text>
                                        <Text style={styles.cell2}><Text style={styles.textBold}>Valor Nominal Unitário
                                        </Text>{'\n'}{formData.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        </Text>
                                        <Text style={[styles.cell2, { borderRightWidth: 0 }]}><Text style={styles.textBold}>Quantidade</Text>{'\n'}
                                            [--] ([--])
                                        </Text>

                                    </View>
                                    <View style={styles.row}>
                                        <Text style={[styles.cell2]}><Text style={styles.textBold}>Conversibilidade
                                        </Text>{'\n'}
                                            <Text style={styles.textBold}>
                                            </Text>Simples, ou seja, não
                                            conversíveis em
                                            participação societária
                                            da Emissora.
                                        </Text>
                                        <Text style={styles.cell2}><Text style={styles.textBold}>Custo da Emissão</Text>{'\n'}
                                            R$ [--] ([--])a ser pago pela(o)
                                            [Emissor OU Titular em nome
                                            do Emissor] à Central
                                            Depositária (conforme abaixo
                                            definida), na conta corrente
                                            abaixo indicada, [na data da
                                            liquidação OU emissão] das
                                            Notas Comerciais.
                                            Banco: Itaú (341)
                                            Agência: 9170
                                            Conta Corrente: 11533-2</Text>
                                        <Text style={styles.cell2}><Text style={styles.textBold}>Garantias</Text>{'\n'}As Notas Comerciais
                                            não contarão com
                                            quaisquer garantias,
                                            sejam reais ou
                                            fidejussórias.</Text>
                                        <Text style={[styles.cell2, { borderRightWidth: 0 }]}><Text style={styles.textBold}>Moeda da Emissão</Text>{'\n'}
                                            Reais (R$)</Text>

                                    </View>
                                    <View style={styles.row}>
                                        <Text style={[styles.cell2]}><Text style={styles.textBold}>Repactuação</Text>{'\n'}
                                            As Notas Comerciais
                                            não serão objeto de
                                            repactuação
                                            programada.</Text>

                                        <Text style={styles.cell2}><Text style={styles.textBold}>Classificação de Risco</Text>{'\n'}
                                            Não será contratada agência de
                                            classificação de risco da
                                            Emissora ou das Notas
                                            Comerciais no âmbito da
                                            Oferta Restrita.</Text>
                                        <Text style={styles.cell2}><Text style={styles.textBold}>Tipo de Oferta
                                        </Text>{'\n'}Privada, isto é: não será realizada oferta pública de valores
                                            mobiliários para fins de colocação das Notas Comerciais
                                        </Text>


                                    </View>
                                    <View style={styles.row}>
                                        <Text style={[styles.cell2]}><Text style={styles.textBold}>Local de Pagamento</Text>{'\n'}
                                            {emissor.cidade}-{emissor.uf}, em
                                            conta do Titular das
                                            Notas Comerciais,
                                            indicado no Boletim de
                                            Subscrição.</Text>

                                        <Text style={styles.cell2}><Text style={styles.textBold}>Atualização Monetária</Text>{'\n'}
                                            As Notas Comerciais não serão
                                            atualizadas monetariamente.</Text>
                                        <Text style={styles.cell2}><Text style={styles.textBold}>Juros
                                            Remuneratórios
                                        </Text>{'\n'}{formData.taxa}% a.m. ({formData.taxa} ao mês),
                                            base 30 (trinta) dias,
                                            sendo certo que a
                                            rentabilidade apenas
                                            se inicia após a
                                            efetiva liquidação
                                            financeira.
                                        </Text>
                                        <Text style={[styles.cell2, { borderRightWidth: 0 }]}><Text style={styles.textBold}>Datas de Pagamento dos Juros
                                            Remuneratórios</Text>{'\n'}
                                            [Única, na Data de Vencimento das
                                            Notas Comerciais.] OU [Mensal,
                                            sempre no dia [--] de cada mês,
                                            conforme fluxo de pagamento
                                            constante no Anexo II]
                                        </Text>

                                    </View>
                                    <View style={styles.row}>
                                        <Text style={[styles.cellRight]}><Text style={styles.textBold}>Data de Pagamento
                                            dos Valor Nominal
                                            Unitário</Text>{'\n'}
                                            [Única, na Data de
                                            Vencimento das Notas
                                            Comerciais.] OU
                                            [Mensal, sempre no dia
                                            {formData.vencimentoDia} de cada mês,
                                            conforme fluxo de
                                            pagamento constante
                                            no Anexo II].</Text>

                                        <Text style={styles.cellRight}><Text style={styles.textBold}>Amortização Extraordinária</Text>{'\n'}
                                            [Não haverá possibilidade de
                                            amortização extraordinária das
                                            Notas Comerciais] OU [Haverá
                                            possibilidade de amortização
                                            extraordinária, seja total ou
                                            parcial, das Notas Comerciais,
                                            desde que amortizados
                                            também os juros incorridos no
                                            respectivo período.]</Text>
                                        <Text style={styles.cellRight}><Text style={styles.textBold}>Resgate
                                            Antecipado
                                            Facultativo Total e
                                            Oferta de Resgate
                                            Antecipado
                                        </Text>{'\n'}[A mercado, com
                                            Direito de
                                            Preferência e Direito
                                            de
                                            Equiparação de
                                            Oferta por parte da
                                            Emissora] OU
                                            [Mediante opagamento dos juros
                                            incorridos no
                                            respectivo período]
                                        </Text>
                                        <Text style={[styles.cellRight, { borderRightWidth: 0 }]}><Text style={styles.textBold}>Resgate Antecipado
                                            Compulsório Total</Text>{'\n'}
                                            As Notas Comerciais deverão ser
                                            resgatadas integralmente caso seja
                                            decretado o Vencimento
                                            Antecipado das Notas Comerciais,
                                            conforme abaixo definido.
                                        </Text>

                                    </View>
                                </View>
                            </View>
                        </Page>
                        {chunkedCalcData.map((chunk, pageIndex) => (
                            <Page style={styles.page}>
                                <View style={styles.section}>
                                    {isFirstPage && (
                                        <View>
                                            <Text style={[styles.textBold, { textAlign: 'center', textDecoration: 'underline', marginBottom: '20px', fontSize: 12 }]}>
                                                ANEXO II – PLANO DE PAGAMENTO
                                            </Text>
                                            <View style={styles.downTable}>
                                                <View style={styles.tr}>
                                                    <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '70px' }]}>Período</Text>
                                                    <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '82px' }]}>Vencimento</Text>
                                                    <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '90px' }]}>Parcela</Text>
                                                    <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '91px' }]}>Amortização</Text>
                                                    <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '88px' }]}>Juros</Text>
                                                    <Text style={[styles.centralizadoTh, { borderRightWidth: '1px', borderRightColor: 'black', width: '100px' }]}>Saldo Devedor</Text>
                                                </View>
                                            </View>
                                            <View style={styles.tr}>
                                                <Text style={[styles.centralizado, { borderLeftWidth: '1px', borderRightWidth: '1px', borderRightColor: 'black', width: '71px' }]}>0</Text>
                                                <Text style={[styles.centralizado, { borderRightWidth: '1px', borderRightColor: 'black', width: '82px' }]}>
                                                    {now.getDate().toString().padStart(2, '0')}/{(now.getMonth() + 1).toString().padStart(2, '0')}/{now.getFullYear()}
                                                </Text>
                                                <Text style={[styles.alinhamentoDireitaTd, { width: '90px' }]}>0</Text>
                                                <Text style={[styles.alinhamentoDireitaTd, { width: '91px' }]}>0</Text>
                                                <Text style={[styles.alinhamentoDireitaTd, { width: '88px' }]}>0</Text>
                                                <Text style={[styles.alinhamentoDireitaTd, { width: '100px' }]}>{formData.total}</Text>
                                            </View>

                                        </View>
                                    )}
                                    {chunk.map((data, index) => (
                                        <View key={index} style={[styles.tr, { borderTopWidth: "1px" }]}>
                                            <Text style={[styles.centralizado, { borderLeftWidth: '1px', borderRightWidth: '1px', borderRightColor: 'black', width: '71px' }]}>{data[0]}</Text>
                                            <Text style={[styles.centralizado, { borderRightWidth: '1px', borderRightColor: 'black', width: '82px' }]}>
                                                {data[1] < 10 ? `0${data[1]}` : data[1]}/{data[2] === '00' ? 12 : data[2]}/{data[3]}
                                            </Text>
                                            <Text style={[styles.alinhamentoDireitaTd, { width: '90px' }]}>
                                                {data[4].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                            </Text>
                                            <Text style={[styles.alinhamentoDireitaTd, { width: '91px' }]}>
                                                {data[5].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                            </Text>
                                            <Text style={[styles.alinhamentoDireitaTd, { width: '88px' }]}>
                                                {data[6].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                            </Text>
                                            <Text style={[styles.alinhamentoDireitaTd, { width: '100px' }]}>
                                                {data[7].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                            </Text>

                                        </View>

                                    ))}
                                </View>
                                {isFirstPage && (pageIndex === 0 ? (isFirstPage = false) : null)}
                            </Page>
                        ))}
                    </Document>
                </PDFViewer>
                <p>
                    Página {pageNumber} de {numPages}
                </p>
            </Container>
        </Box>
    );
};

export default Minuta;
